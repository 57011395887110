import { Box } from "@mui/material";
import { DataGrid, GridPaginationModel, GridSortModel } from "@mui/x-data-grid";

import { useMemo, useRef } from "react";
import useStaffDirectoryTableColumnDefinition, { StaffDirectoryRecords } from "../../hooks/table/use_staff_directory_table_column_definition";
import { StaffDirectoryDataGridNoRowsOverlay } from "./staff_directory_datagrid_no_rows_overlay";

type Props = {
  rows: StaffDirectoryRecords;
  // columnDef: ReturnType<typeof useSessionLogsReportTableColumnDefinition>["sessionLogsReportTablecolumns"];
  paginationState: {
    paginationModel: GridPaginationModel;
    setPaginationModel: (paginationModel: GridPaginationModel) => void;
    totalRecords?: number;
  };
  sortingState: {
    sortModel: GridSortModel;
    setSortModel: (sortingModel: GridSortModel) => void;
  };
  onRowClick?: (row: StaffDirectoryRecords[number]) => void;
  loading: boolean;
};

export function StaffDirectoryTable(props: Readonly<Props>) {
  const columnDef = useStaffDirectoryTableColumnDefinition().staffDirectoryColumnDefinitions;

  const rowCountRef = useRef(props.paginationState.totalRecords ?? 0);

  const rowCount = useMemo(() => {
    if (props.paginationState.totalRecords !== undefined) {
      rowCountRef.current = props.paginationState.totalRecords;
    }
    return rowCountRef.current;
  }, [props.paginationState.totalRecords]);

  return (
    <Box
      aria-label="staff-directory-table"
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "800px",
        minHeight: "600px",
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "contrasts.1",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          justifyContent: "left",
        },
        "& [role=row]:hover": {
          // color: "text.primary",
          cursor: "pointer",
        },
      }}
    >
      <DataGrid
        autoHeight
        rows={props.rows ?? []}
        columns={columnDef}
        rowCount={rowCount}
        slots={{
          noRowsOverlay: StaffDirectoryDataGridNoRowsOverlay("No Session Logs Data found"),
        }}
        disableRowSelectionOnClick
        sortingMode="server"
        sortingOrder={["asc", "desc"]}
        sortModel={props.sortingState.sortModel}
        onSortModelChange={(newSortModel) => props.sortingState.setSortModel(newSortModel)}
        disableColumnSelector
        pagination
        disableColumnFilter
        paginationMode="server"
        initialState={{
          pagination: { paginationModel: { pageSize: 10, page: 0 } },
        }}
        onRowClick={(params) => {
          console.log(params.row);props.onRowClick?.(params.row);
        }}
        paginationModel={props.paginationState.paginationModel}
        onPaginationModelChange={props.paginationState.setPaginationModel}
        pageSizeOptions={[10, 20, 50, 100]}
        loading={props.loading}
      />
    </Box>
  );
}
