import { Alert, Box, Snackbar } from "@mui/material";
import UnpostedSessionsCard from "../components/cards/unposted_sessions_card";
import useUnpostedSessionsContext from "../hooks/use_unposted_sessions_context";
import { useNavigate, useParams } from "react-router-dom";
import useUnpostedSessionsMatchPath from "../hooks/use_unposted_sessions_match_path";
import UnpostedSessionsCardSkeleton from "../components/cards/unposted_sessions_card_skeleton";
import { ROUTES_XLOGS } from "../../../constants/URLs";
import useUnpostedSessionsViewState from "../hooks/use_unposted_sessions_view_state";
import { useCallback, useMemo, useState } from "react";
import { useXNGDispatch, useXNGSelector } from "../../../context/store";
import { selectServiceProviderProfile } from "../../../context/slices/loggedInClientSlice";
import produce from "immer";
import { useCreateSessionInDatabase } from "../../../hooks/use_create_session_in_database";
import {
  ServiceProviderRef,
  SessionDayViewCard,
  SessionResponse,
  SessionSlimCard,
} from "../../../session-sdk";
import { pushError } from "../../../context/slices/errorEntries";
import useRefetchUnpostedSessionsRequests from "../hooks/use_refetch_unposted_sessions_requests";

const UnpostedSessionsView = () => {
  const viewParamId = useParams().viewId as ReturnType<
    typeof useUnpostedSessionsMatchPath
  >["customId"];
  const navigate = useNavigate();
  const context = useUnpostedSessionsContext();
  const loggedServiceProvider = useXNGSelector(selectServiceProviderProfile);
  const viewState = useUnpostedSessionsViewState({
    viewParamId,
    context,
  });

  const sortedServiceProviders = useMemo(() => {
    if (viewParamId === "dec-sessions") {
      const sortedSelectedServiceProviders = produce(
        viewState.state.selectedServiceProviders,
        (draftState) => {
          draftState.sort((a, b) => {
            return a.id === loggedServiceProvider?.id ? -1 : 1;
          });
        },
      );
      return sortedSelectedServiceProviders;
    } else {
      return viewState.state.selectedServiceProviders;
    }
  }, [loggedServiceProvider?.id, viewParamId, viewState.state.selectedServiceProviders]);

  const [showSessionClickErrorMessage, setShowSessionClickErrorMessage] = useState(false);
  const clickSessionErrorMessage =
    "Session is either missing both an id and seriesId or missing a startTime";

  const createSessionInDatabase = useCreateSessionInDatabase();
  const dispatch = useXNGDispatch();
  const { refetchOnSessionAction } = useRefetchUnpostedSessionsRequests();

  const navigateToSession = useCallback(
    (session: SessionSlimCard, serviceProvider: ServiceProviderRef) => {
      viewState.dispatch({
        type: "set_current_session_in_notator_view",
        payload: {
          id: session?.id ?? null,
          startTime: session.startTime!, // assert for now (unsafe)
          seriesId: session.seriesId ?? null,
        },
      });
      const path = `${ROUTES_XLOGS.unposted_sessions.index}/${viewParamId}/notator/${session?.id}?serviceProviderId=${serviceProvider.id}&seriesId=${session?.seriesId}&date=${session?.startTime}`;
      navigate(path);
    },
    [viewState, navigate, viewState.dispatch],
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          width: "100%",
          overflowX: "auto",
          height: "100%",
        }}
      >
        {/* RENDER SKELETON IF LOADING */}
        {(viewState.apiQueryClientManager.isLoading || !viewState.state.hasInitializedState) &&
          Array.from({ length: 10 }).map(() => {
            return <UnpostedSessionsCardSkeleton key={crypto.randomUUID()} />;
          })}
        {viewState.state.selectedServiceProviders.length === 0 && (
          <Alert severity="info" sx={{ fontWeight: 600, alignSelf: "start", flex: 1 }}>
            No service providers Selected
          </Alert>
        )}
        {/* RENDER DATA ON SUCCESS */}
        {viewState.state &&
          viewState.state.hasInitializedState &&
          viewState.apiQueryClientManager.isSuccess &&
          sortedServiceProviders.map((serviceProvider) => {
            return (
              <UnpostedSessionsCard
                key={serviceProvider.id ?? crypto.randomUUID()}
                serviceProvider={serviceProvider}
                sessionsData={viewState.state.selectedServiceProvidersFilteredSessions.get(
                  serviceProvider.id ?? "",
                )}
                totalUnpostedSessions={
                  viewState.state.totalProviderUnpostedSessions.get(serviceProvider.id ?? "") ?? 0
                }
                // disable={diableCards}
                mySessions={
                  viewParamId === "my-sessions" || serviceProvider.id === loggedServiceProvider?.id
                }
                onSessionBtnClick={async (session) => {
                  if ((!session.id && !session.seriesId) || !session.startTime) {
                    console.error(clickSessionErrorMessage);
                    setShowSessionClickErrorMessage(true);
                    return;
                  }

                  if (session.id === null) {
                    const [sessionResult, error] = await createSessionInDatabase(
                      sessionSlimCardToSessionDayViewCard(session, { serviceProvider }),
                    );
                    if (error || !sessionResult) {
                      dispatch(
                        pushError(error ?? { titleID: "Failure attempting to create session" }),
                      );
                      return;
                    }

                    refetchOnSessionAction();

                    const slimCard = sessionResultToSlimCard(sessionResult);
                    navigateToSession(slimCard, serviceProvider);
                    return;
                  }

                  navigateToSession(session, serviceProvider);
                }}
              />
            );
          })}
      </Box>
      {/* AUXILIARY COMPONENTS */}
      <Snackbar
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        open={showSessionClickErrorMessage}
        autoHideDuration={6000}
        onClose={() => setShowSessionClickErrorMessage(false)}
        message={clickSessionErrorMessage}
      />
    </>
  );
};

/**
 * Accepts a SessionSlimCard and returns an equivalent SessionDayViewCard. Can accept extra parameters in `options` for a further hydrated result.
 *
 * NOTE: Currently we don't map campuses from SessionSlimCard. I don't believe SessionDayViewCard actually has a point for this to map to.
 */
function sessionSlimCardToSessionDayViewCard(
  s: SessionSlimCard,
  options?: { serviceProvider?: ServiceProviderRef },
): SessionDayViewCard {
  const serviceProvider = options?.serviceProvider;

  return {
    id: s.id,
    seriesId: s.seriesId,
    startTime: s.startTime,
    totalStudents: s.students,
    title: s.title,
    serviceProvider,
  };
}

function sessionResultToSlimCard(s: SessionResponse): SessionSlimCard {
  return {
    id: s.id,
    seriesId: s.seriesId,
    title: s.title,
    startTime: s.meetingDetails?.startTime,
    students: s.studentJournalList?.map((s) => ({
      firstName: s.student?.firstName,
      id: s.student?.id,
      lastName: s.student?.lastName,
    })),
    campuses: [], // NOTE: Not sure what this is, but it isn't needed for the PR that adds this function. Update later if needed
  };
}

export default UnpostedSessionsView;
