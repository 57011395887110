import dayjs from "dayjs";
import { API_SESSIONS } from "../api/api";
import { ErrorEntry } from "../context/slices/errorEntries";
import { selectStateInUS } from "../context/slices/stateInUsSlice";
import { useXNGSelector } from "../context/store";
import { SessionDayViewCard, SessionResponse } from "../session-sdk";

type SessionResponseOrErrorTuple = [SessionResponse | null, ErrorEntry | null];

export function useCreateSessionInDatabase(): (
  session: SessionDayViewCard,
) => Promise<SessionResponseOrErrorTuple> {
  const stateInUS = useXNGSelector(selectStateInUS);

  return async function createSessionInDatabase(
    virtualizedSession: SessionDayViewCard,
  ): Promise<SessionResponseOrErrorTuple> {
    if (virtualizedSession.serviceProvider?.id === null) {
      return [
        null,
        new ErrorEntry({
          titleID: "Calendar session service provider ID was null",
          codeBlockMessage: JSON.stringify(virtualizedSession),
        }),
      ];
    }

    try {
      const newSession = await API_SESSIONS.v1SessionsGet(
        virtualizedSession.serviceProvider!.id!,
        stateInUS,
        undefined,
        virtualizedSession.seriesId,
        dayjs(virtualizedSession.startTime).toDate(),
      );

      if (!newSession) {
        throw new Error("Could not read SessionResponse!");
      }

      return [await API_SESSIONS.v1SessionsPut(stateInUS, newSession), null];
    } catch (e) {
      return [
        null,
        new ErrorEntry({
          titleID: "API Call Failed",
          tryCatchErrorMessage: e,
        }),
      ];
    }
  };
}
