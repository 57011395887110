import React from 'react'
import { Box, Grid, SxProps, Typography } from '@mui/material'
import ProgressReportingStandardBtn from './progress_reporting_standard_btn'
import EditGoalProgressFilterTable from './goal_edit_filter_table'
import ObjectivesEditFilterTable from './objectives_edit_filter_table'
import { SettingsPopup } from './popup'
import { useEffect, useRef, useState } from 'react'
import LineChart from '../../../../../fortitude/amcharts/line_chart'
import { useReactToPrint } from 'react-to-print'
import useProgressReporting from '../context/hooks/useProgressReporting' 
import { LineChartType, ProgressLineChartHelper } from '../utils/progress_line_chart_helper'
import { getFormattedDateTime } from '../../../../admin/views/district_profile/utils/date_utils'
import { ProgressReportingActionType } from '../context/progress_reporting_actions'
import {getGoalStatus} from '../utils/getGoalStatus'
import { FormattedStudentReport, xlsxReportGeneratorManager } from '../utils/xlsx_helper'
import { generateCsvReport } from '../utils/csv_helper'
import { PollingStatus } from '../types/progress_reporting_context_type'
import FullPageLoadingScreen from '../../../../../design/high-level/common/full_page_loading_screen' 
import DownloadModal from './download_modal'
import { progressReportTransformationPipeline } from '../utils/report_transformation_pipeline'
import { GroupedStudentReport } from '../types/extended_progress_repoting_types';
import IfElseBox from '../../../../../design/components-dev/if_else_box';
import { pushError } from '../../../../../context/slices/errorEntries';
import { useXNGDispatch } from '../../../../../context/store';  
import { isValidArray } from '../utils/validators';
import { write } from 'xlsx'

interface Edit_FILTER_ACTION_PROPS {
    title?: string
    onSubmit?: () => void
    sxOverRide?: SxProps
}
export enum FilterButtonTitle {
    Settings = "Settings",
    AddReportingPeriod = "Add Reporting Period",
    Print = "Print",
    Download = "Download",
    Post = "Post",
    Save = "Save"
}

const ProgressReportGenerated = () => {
    const dispatch = useProgressReporting()?.dispatch
    const contentRef = useRef<HTMLDivElement>(null)
    const progressReportDataFetched = useProgressReporting()?.state?.studentProgressReportFetched
    const [isPrinting, setIsPrinting] = useState<{status:boolean,type:"pdf"|"csv"|"xlsx"}>({status:false,type:"pdf"});
    const onBeforePrintResolve: React.MutableRefObject<((value: void | PromiseLike<void>) => void) | null> = React.useRef(null);
    const pollingStatus = useProgressReporting()?.state?.pollingStatus 
    const [isDownloadModalOpen,setIsDownloadModalOpen] = useState(false)
    const isTeamAggregated = useProgressReporting()?.state?.settingsPopup?.showTeamAggregatedData 
    const isLastSessionData = useProgressReporting()?.state?.settingsPopup?.showLastSession
    const aggregatedProgressData = useProgressReporting()?.state?.aggregatedProgressReport
    const teamAggregatedProgressData = useProgressReporting()?.state?.teamAggregatedProgressReport
    const isPerformingAggregation = useProgressReporting()?.state?.isPerformingAggregation
    const isFinalizingProgressReport = useProgressReporting()?.state?.isFinalizingProgressReport
    const [isAggregating,setIsAggregating] = useState(false)
    const errorDispatcher = useXNGDispatch();
        
    useEffect(() => {
        if(!isPerformingAggregation && isAggregating){
            setIsAggregating(false)
            setIsDownloadModalOpen(true)
        }
    },[isPerformingAggregation,isAggregating])

    useEffect(() => {
        if (!isPrinting.status) {
            dispatch({ type: ProgressReportingActionType.SET_PRINTING_SIGNAL, payload: false });
        }
    }, [isPrinting])


    const handleOnBeforePrint = React.useCallback(() => {
        return new Promise<void>((resolve) => {
            onBeforePrintResolve.current = resolve;
            setTimeout(() => {
                resolve();
            }, 2000);
        });
    }, []);

    const handleOnAfterPrint = React.useCallback(() => {
        setIsPrinting({status:false,type:"pdf"});
    }, []);


    const handlePrint = useReactToPrint({
        contentRef: contentRef,
        onBeforePrint: handleOnBeforePrint,
        onAfterPrint: handleOnAfterPrint,
        pageStyle: `
          @page {
            size: auto;
            margin: 10mm;
            size: portrait;
          }
              * {
                    box-sizing: border-box;
                }
                    
          html, body {
            height: 100%;
            margin: 0;
            padding: 0;
          }  
             table, div, p {
                            break-inside: avoid;
                            page-break-inside: avoid; /* Ensures the element doesn't break inside */
                            }
            no-break {
                page-break-inside: avoid;
                }

         .footer{ 
            page-break-after: always; 
         }     
        `,
    });

    // Trigger the download of the CSV file
    const downloadCSV = (data: FormattedStudentReport[]) => {
        const blob = generateCsvReport(data,isTeamAggregated,isLastSessionData);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'progress_report.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setIsPrinting({status:false,type:"csv"});
        setIsDownloadModalOpen(false)
    };

    const downloadExcel = (data: FormattedStudentReport[]) => {
        let workbook = xlsxReportGeneratorManager.generateXlsxReport(data,isTeamAggregated,isLastSessionData);
        const xlsxFile = write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([xlsxFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'progress_report.xlsx');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        dispatch({ type: ProgressReportingActionType.SET_GENERATING_XLSX_SIGNAL, payload: false })
        dispatch({ type: ProgressReportingActionType.SET_PRINTING_SIGNAL, payload: false })
        setIsPrinting({status:false,type:"xlsx"});
        setIsDownloadModalOpen(false)
    };

    const EDIT_FITER_ACTIONS: Edit_FILTER_ACTION_PROPS[] = [
        {
            title: FilterButtonTitle.Settings,
            onSubmit: () => { }
        },
        // {
        //     title: FilterButtonTitle.AddReportingPeriod,
        //     onSubmit: () => { }
        // },
        {
            title: FilterButtonTitle.Print,
            onSubmit: () => {
                setIsPrinting({status:true,type:"pdf"});
                dispatch({ type: ProgressReportingActionType.SET_PRINTING_SIGNAL, payload: true })
                setTimeout(() => { handlePrint() }, 100);

            }
        },
        {
            title: FilterButtonTitle.Download,
            onSubmit: () => {
                if(isPerformingAggregation && isTeamAggregated){
                    setIsAggregating(true)
                    return
                }
                setIsDownloadModalOpen(true)
            }
        },

        /** might enable in future if needed */

        // {
        //     title: FilterButtonTitle.Post,
        //     onSubmit: () => { }
        // },
        // {
        //     title: FilterButtonTitle.Save,
        //     onSubmit: () => { },
        //     sxOverRide: {
        //         background: "red",
        //         color: "white"
        //     }
        // }
    ]
 
    const downloadCsvFromModal =()=>{
        let csvSanitizedData = []
        
        if(isTeamAggregated){
            if(!teamAggregatedProgressData || Array.isArray(teamAggregatedProgressData) && teamAggregatedProgressData.length === 0){
                displayError(
                     new Error("No data to print")
                 )
                 return
            }
            // this should not have duplicates for each student
            csvSanitizedData = xlsxReportGeneratorManager.sortByStudentForGroupedStudentReport(teamAggregatedProgressData ?? [] )
        } else {
            // this should not have duplicate goals for each student 
            if(!aggregatedProgressData || Array.isArray(aggregatedProgressData) && aggregatedProgressData.length === 0){
                displayError(
                     new Error("No data to print")
                 )
                 return 
            }
            csvSanitizedData = xlsxReportGeneratorManager.sortByStudentForGroupedStudentReport(aggregatedProgressData ?? [])
        }
        
        setIsPrinting({
            type:"csv",
            status:true
        });
        downloadCSV(csvSanitizedData)
        dispatch({ type: ProgressReportingActionType.SET_GENERATING_XLSX_SIGNAL, payload: true })
        dispatch({ type: ProgressReportingActionType.SET_PRINTING_SIGNAL, payload: true })
    }

    const downloadXlsxFromModal =()=>{ 
        let xlsxSanitizedData = []
         
        if(isTeamAggregated){
            if(!teamAggregatedProgressData || Array.isArray(teamAggregatedProgressData) && teamAggregatedProgressData.length === 0){
                displayError(
                     new Error("No data to print")
                 )
                 return
            }
            // this should not have duplicates for each student
            xlsxSanitizedData = xlsxReportGeneratorManager.sortByStudentForGroupedStudentReport(teamAggregatedProgressData ?? [] )
        } else {
            // this should not have duplicate goals for each student 
            if(!aggregatedProgressData || Array.isArray(aggregatedProgressData) && aggregatedProgressData.length === 0){
                displayError(
                     new Error("No data to print")
                 )
                 return 
            }
            xlsxSanitizedData = xlsxReportGeneratorManager.sortByStudentForGroupedStudentReport(aggregatedProgressData ?? [])
        }
        setIsPrinting({
            type:"xlsx",
            status:true
        });
        dispatch({ type: ProgressReportingActionType.SET_GENERATING_XLSX_SIGNAL, payload: true })
        dispatch({ type: ProgressReportingActionType.SET_PRINTING_SIGNAL, payload: true }) 
        downloadExcel(xlsxSanitizedData) 
    }

    async function displayError(arg: Error) {
        errorDispatcher(
               pushError({ 
                  titleID: "Failed to fetch student progress report!", 
                  message: arg?.message 
                }))
      }
   

    if (pollingStatus === PollingStatus.Idle)
        return (
            <Box>
                {isDownloadModalOpen && <DownloadModal isOpen={isDownloadModalOpen} downloadXlsx={downloadXlsxFromModal} 
                handleClose={()=>{ 
                    setIsDownloadModalOpen(false)}} downloadCSV={downloadCsvFromModal} />}
                { /** Edit Filter Actions */}
                {isPrinting.status && <FullPageLoadingScreen text="Generating report..." pulse />}
                {isAggregating && <FullPageLoadingScreen text="Generating Report Documents..." pulse />}
                {isFinalizingProgressReport && <FullPageLoadingScreen text="Finalizing Progress..." pulse /> }
                <Box sx={{ display: "flex", gap: "1rem", flexWrap: "wrap", flexDirection: "row", alignItems: "center" }}>
                    {/* ACTIONS OVER PROGRESS REPORT GENERATED */}
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", gap: "10px" }}>
                        {
                            EDIT_FITER_ACTIONS
                                .map(
                                    (editFilterAction: Edit_FILTER_ACTION_PROPS, idx) =>
                                        <PopupRenderer key={idx} buttonName={editFilterAction.title as FilterButtonTitle}>
                                            <ProgressReportingStandardBtn
                                                title={editFilterAction.title}
                                                sxOverRide={editFilterAction.sxOverRide as SxProps}
                                                onSubmit={editFilterAction.onSubmit}
                                                key={editFilterAction.title} />
                                        </PopupRenderer>
                                )
                        }
                    </Box>
                    {/***REPORT TITLE */}
                    <Box ref={contentRef} sx={{ display: "flex", alignItems: "center", gap: "1rem", width: "100%", flexWrap: "wrap", flexDirection: "row" }}>
                        {progressReportDataFetched?.pageRecords?.length === 0 &&<Box sx={{ width: "100%", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography variant="subtitle2" sx={{ fontSize: "24px", fontWeight: 700 }}>Report of Student's Progress Toward Individualized Education Plan Goals/Objectives</Typography>
                        </Box>}
                        {/*  conditionally render based on viewType */}
                        <ToggleBasedView />
                    </Box>
                    <Box sx={{ width: "100%", height: "150px" }} />
                </Box>
            </Box>
        )

    return null
}


const ToggleBasedView:React.FC =()=>{
    const progressReportDataFetched =  useProgressReporting()?.state?.studentProgressReportFetched 
    const progressReportDataMasteryCopyWithPostUnpostStatus = useProgressReporting()?.state?.progressReportGeneratedMasterCopy
    const serviceProviderSelected = useProgressReporting()?.state?.settingsPopup?.serviceProviderSelected
    const dispatch = useProgressReporting()?.dispatch
    const isTeamAggregated = useProgressReporting()?.state?.settingsPopup?.showTeamAggregatedData 
    const [teamAggregatedData,setTeamAggregatedData] = React.useState<GroupedStudentReport[]>([])
    const teamAggregatedDataGlobalState = useProgressReporting()?.state?.teamAggregatedProgressReport
    // this function filter progress report based on the selected service provider
    const memoisedGroupedStudentReportReportedByCurrentlySelectedServiceProvider = React.useMemo(()=>
        progressReportTransformationPipeline.groupProgressReportByStudentsFindByServiceProviderId(progressReportDataFetched?.pageRecords ?? [],serviceProviderSelected?.id as string),
    [progressReportDataFetched?.pageRecords,serviceProviderSelected])

 

    //handle client side memoised aggregation here (if team aggregated)
    React.useEffect(()=>{
        if(!isTeamAggregated) return 
        dispatch({
            type:ProgressReportingActionType.SET_PERFORMING_AGGREGATION_LOADING
        })
        let teamAgg = progressReportTransformationPipeline.getAggregatedProgressReportGrouped(progressReportDataFetched?.pageRecords ?? [],progressReportDataMasteryCopyWithPostUnpostStatus?.pageRecords ?? [])
        setTeamAggregatedData(teamAgg)
        dispatch({
            type:ProgressReportingActionType.SET_PERFORMING_AGGREGATION_LOADING
        }) 
    }, [progressReportDataFetched?.pageRecords,isTeamAggregated,progressReportDataMasteryCopyWithPostUnpostStatus]) 
 

    useEffect(()=>{
        dispatch({
            type:ProgressReportingActionType.SET_AGGREGATED_GROUPED_PROGRESS_REPORT,
            payload: memoisedGroupedStudentReportReportedByCurrentlySelectedServiceProvider
        }) 
    },[memoisedGroupedStudentReportReportedByCurrentlySelectedServiceProvider])

    useEffect(()=>{ 
        dispatch({
            type:ProgressReportingActionType.SET_TEAM_AGGREGATED_GROUPED_PROGRESS_REPORT,
            payload: teamAggregatedData
        })
    },[teamAggregatedData])

    const isDataAvailable = !!(  progressReportDataFetched?.pageRecords?.length &&
        progressReportDataFetched.pageRecords.some(arg => arg?.goal?.goalId) )
    const isGoalOrObjectiveAvailableUnderSelectedServiceProvider = memoisedGroupedStudentReportReportedByCurrentlySelectedServiceProvider?.length !== 0
    
 
    if(!isTeamAggregated){
        return (
        <IfElseBox if={isGoalOrObjectiveAvailableUnderSelectedServiceProvider} 
        then={ <React.Fragment>
                    { memoisedGroupedStudentReportReportedByCurrentlySelectedServiceProvider?.map((individualReport, idx)=>(
                            <ProgressReportContent key={`progress-report-content-${individualReport.studentName}-${idx}`} itemNumber={idx} individualProgress={individualReport}/>
                        )) }
                </React.Fragment> } 
        else={<NoGoalsIndicator/>}/>)
    }
    
    return (
        <IfElseBox if={isDataAvailable} 
        then={ <React.Fragment>
                    { teamAggregatedDataGlobalState?.map((individualReport, idx)=>(
                            <ProgressReportContent key={`progress-report-aggregated-${individualReport?.studentName}-${idx}`} itemNumber={idx} individualProgress={individualReport}/>
                        )) }
                </React.Fragment> } 
        else={<NoGoalsIndicator/>}/>)
}

const NoGoalsIndicator=()=>(
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                <Typography variant="subtitle2" sx={{ fontSize: "24px", textAlign: "center", fontWeight: 700, color: "red" }}>
                    {"No goals on file"}
                </Typography>
    </Box>
)

const ProgressReportContent:React.FC<{individualProgress:GroupedStudentReport,itemNumber:number}>=({individualProgress,itemNumber})=>{
    const isObjectiveViewToggleEnabled = useProgressReporting()?.state?.settingsPopup?.showObjectives
    const isTeamAggregated = useProgressReporting()?.state?.settingsPopup?.showTeamAggregatedData
    const isLastSessionData = useProgressReporting()?.state?.settingsPopup?.showLastSession
    return(
        <Box className={"no-break"}  sx={{ display: "flex", flexWrap: "wrap", flexDirection: "row",gap:"20px" }}>
                                    {/* district and reporting period */} 
                                    {itemNumber===0 && <Box sx={{ width: "100%", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Typography variant="subtitle2" sx ={{ fontSize: "24px", fontWeight: 700 }}>Report of Student's Progress Toward Individualized Education Plan Goals/Objectives</Typography>
                                    </Box>}
                                           <StudentDetailsSection  district={individualProgress?.district as string}
                                                                    reportingPeriod={`${
                                                                        isValidArray(individualProgress.allStudentProgressReportUnderCurrentStudent) ?
                                                                        (isValidArray(individualProgress.allStudentProgressReportUnderCurrentStudent[0]?.goal?.goalProgressDetails) ? 
                                                                        getFormattedDateTime(individualProgress.allStudentProgressReportUnderCurrentStudent[0]?.goal?.goalProgressDetails[
                                                                            individualProgress.allStudentProgressReportUnderCurrentStudent[0]?.goal?.goalProgressDetails.length - 1
                                                                        ].periodStartDate?.toDateString() as string).date :
                                                                        "NA")
                                                                        : "NA"
                                                                    }-${
                                                                        isValidArray(individualProgress.allStudentProgressReportUnderCurrentStudent) ?
                                                                        (isValidArray(individualProgress.allStudentProgressReportUnderCurrentStudent[0]?.goal?.goalProgressDetails) ? 
                                                                        getFormattedDateTime(individualProgress.allStudentProgressReportUnderCurrentStudent[0]?.goal?.goalProgressDetails[
                                                                            individualProgress.allStudentProgressReportUnderCurrentStudent[0]?.goal?.goalProgressDetails.length - 1
                                                                        ].periodEndDate?.toDateString() as string).date :
                                                                        "NA")
                                                                        : "NA"
                                                                    }`}
                                                                    studentName={individualProgress?.studentName as string}
                                                                    grade={individualProgress?.grade as string}
                                                                    campusName={individualProgress?.campus as string}
                                                                    dob={individualProgress.dob?.toDateString() as string}
                                                                /> 

                                        {
                                            individualProgress.allStudentProgressReportUnderCurrentStudent?.map((groupedStudentReports, idx) =>(
                                                   <ActiveGoalsAndObjectivesConditionalRenderer key={`${groupedStudentReports?.goal?.goalId}-activegoal-${idx}`} isCurrentGoalOrObjectiveActive={getGoalStatus(groupedStudentReports?.goal?.goalStatus as string) === "Active"}>
                                                     {/* GOAL REPORT */}
                                                        <Box sx={{ border: "1px solid black", width: "100%", padding: "10px" }}>
                                                            <GoalOrObjectiveDetailsSection 
                                                                            goalOrObjectiveArea={groupedStudentReports.goal?.goalArea as string}
                                                                            reportingPeriodStart={groupedStudentReports.reportingPeriodStart?.toDateString() as string}
                                                                            reportingPeriodEnd={groupedStudentReports.reportingPeriodEnd?.toDateString() as string}
                                                                            goalStatus={groupedStudentReports?.goal?.goalStatus as string}
                                                                            isGoal
                                                            />
                                                            <Typography sx={{ marginTop: "10px", fontSize: "16px", fontWeight: 700 }}>{groupedStudentReports?.goal?.goalDescription}</Typography>
                                                            <EditGoalProgressFilterTable progressReportId={groupedStudentReports?.id as string} reportRunId={groupedStudentReports?.reportRunId as string} sx={{ marginTop: "10px" }} goalId={groupedStudentReports?.goal?.goalId as string} goalProgressDetails={groupedStudentReports?.goal?.goalProgressDetails ?? []} />
                                                            <LineGraphWithHideBtn key={groupedStudentReports?.goal?.goalId as string} data={ProgressLineChartHelper.transformToLineChartData(groupedStudentReports?.goal?.goalProgressDetails ?? [],isTeamAggregated,isLastSessionData)} />
                                                        </Box>
                                                        {/* Objective Report */}
                                                        {isObjectiveViewToggleEnabled &&isValidArray(groupedStudentReports?.objectives) && 
                                                                    groupedStudentReports?.objectives?.map((objective, idx) => (
                                                                        <Box key={`${objective?.objectiveId}-objective-${idx}`} sx={{marginTop: "20px", border: "1px solid black", width: "100%", padding: "10px", marginLeft: "50px" }}>

                                                                                    <GoalOrObjectiveDetailsSection 
                                                                                            goalOrObjectiveArea={objective?.objectiveArea as string}
                                                                                            reportingPeriodStart={groupedStudentReports?.reportingPeriodStart?.toDateString() as string}
                                                                                            reportingPeriodEnd={groupedStudentReports?.reportingPeriodEnd?.toDateString() as string}
                                                                                            goalStatus={objective?.goalStatus as string}
                                                                                            isGoal={false}
                                                                                    />
                                                                                   <Typography sx={{ marginTop: "10px", fontSize: "16px", fontWeight: 700 }}>{objective?.objectiveDescription}</Typography>
                                                                                   <ObjectivesEditFilterTable objectiveId={objective?.objectiveId as string} objectiveDetailsArrayPosition={idx} progressReportId={groupedStudentReports?.id as string} reportRunId={groupedStudentReports?.reportRunId as string} sx={{ marginTop: "10px" }} goalId={groupedStudentReports?.goal?.goalId as string} objectiveProgressDetails={objective?.objectiveProgressDetails ?? []} />
        
                                                                                     {/* line chart here */} 
                                                                                   <LineGraphWithHideBtn key={objective?.objectiveId as string} data={ProgressLineChartHelper.transformToLineChartData(objective?.objectiveProgressDetails ?? [],isTeamAggregated,isLastSessionData)} />
                                                                        </Box>
                                                                    ))
                                                                        
                                                                
                                                            } 
                                                   </ActiveGoalsAndObjectivesConditionalRenderer>
                                            ))
                                        }  
        </Box>
    )
}


const StudentDetailsSection = (props: { district: string, reportingPeriod: string, studentName: string, grade: string, campusName: string, dob: string }) => {
    const { district, reportingPeriod, studentName, grade, campusName, dob } = props
 
 
    return (
        <Grid direction={"row"} container>
            <Grid item lg={6} md={6} sm={6} >
                <Box sx={{ display: "flex",marginBottom: "1rem", flexDirection: "row", alignItems: "center", gap: "10px", }}>
                    <Typography variant="subtitle2" sx={{ fontSize: "16px", fontWeight: 700 }}>District: </Typography>
                    <Typography variant="subtitle2" sx={{   fontSize: "16px", fontWeight: 500 }}>{district}</Typography>
                </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} >
                <Box sx={{ display: "flex", marginBottom: "1rem", flexDirection: "row", alignItems: "center", gap: "20px" }}>
                    <Typography variant="subtitle2" sx={{ fontSize: "16px", fontWeight: 700, }}>Reporting Period: </Typography>
                    <Typography variant="subtitle2" sx={{ fontSize: "16px", fontWeight: 500, }}>{reportingPeriod}</Typography>
                </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} >
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", }}>
                </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} >
                <Box sx={{ display: "flex", marginBottom: "1rem", flexDirection: "row", alignItems: "center", gap: "10px", }}>
                    <Typography variant="subtitle2" sx={{  fontSize: "16px", fontWeight: 700 }}>Grade: </Typography>
                    <Typography variant="subtitle2" sx={{  fontSize: "16px", fontWeight: 500 }}>{grade}</Typography>
                </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} >
                <Box sx={{ display: "flex", marginBottom: "1rem", flexDirection: "row", alignItems: "center", gap: "10px", }}>
                    <Typography variant="subtitle2" sx={{ fontSize: "16px", fontWeight: 700 }}>Student Name: </Typography>
                    <Typography variant="subtitle2" sx={{ fontSize: "16px", fontWeight: 500 }}>{studentName}</Typography>
                </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} >
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
                    <Typography variant="subtitle2" sx={{ fontSize: "16px", fontWeight: 700 }}>Date of birth: </Typography>
                    <Typography variant="subtitle2" sx={{ fontSize: "16px", fontWeight: 500 }}>{`${getFormattedDateTime(dob as unknown as string)?.date}`}</Typography>
                </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} >
                <Box sx={{ display: "flex", marginBottom: "1rem", flexDirection: "row", alignItems: "center", gap: "20px" }}>
                    <Typography variant="subtitle2" sx={{  fontSize: "16px", fontWeight: 700 }}>Campus: </Typography>
                    <Typography variant="subtitle2" sx={{  fontSize: "16px", fontWeight: 500 }}>{campusName}</Typography>
                </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} />  
        </Grid>
    ) 

}

const GoalOrObjectiveDetailsSection =(props:{
    goalOrObjectiveArea:string,reportingPeriodStart:string,reportingPeriodEnd:string,goalStatus:string,isGoal:boolean
})=>{
    const {goalOrObjectiveArea,reportingPeriodStart,reportingPeriodEnd,goalStatus,isGoal} = props
    const isPrinting = useProgressReporting()?.state?.printingSignal
    return (
        <Grid container direction={"row"}>
            <Grid item lg={3} md={3} sm={4} >
                <Box sx={{ display: "flex",whiteSpace: "nowrap", flexDirection: "row", alignItems: "center", gap: "10px", marginTop: "10px" }} >
                    <Typography variant="subtitle2" sx={{ fontSize: "16px", fontWeight: 700 }}>   {isGoal?'Goal':'Objective'} Area: </Typography>
                    <Typography variant="subtitle2" sx={{ fontSize: "16px", fontWeight: 500 }}> {goalOrObjectiveArea}</Typography>
                </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} sx={{marginLeft:isPrinting?"100px":"0px"}} >
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginTop: "10px" }} >
                    <Typography variant="subtitle2" sx={{ fontSize: "16px", fontWeight: 700 }}>  {isGoal?"Goal":"Objective"} Dates:</Typography>
                    <Typography variant="subtitle2" sx={{ fontSize: "16px", fontWeight: 500 }}>{getFormattedDateTime(reportingPeriodStart as unknown as string)?.date + "-" + getFormattedDateTime(reportingPeriodEnd as unknown as string)?.date}</Typography>
                </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={12} >
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginTop: "10px" }} >
                    <Typography variant="subtitle2" sx={{ marginBottom: "1rem", fontSize: "16px", fontWeight: 700 }}>
                        Goal Status:
                    </Typography>
                    <Typography variant="subtitle2" sx={{ marginBottom: "1rem", fontSize: "16px", fontWeight: 500 }}>
                        {getGoalStatus(goalStatus)}
                    </Typography>
                </Box>

            </Grid>
        </Grid>
    )
}


const LineGraphWithHideBtn = (props: { data: LineChartType[],xAxisLabel?:string,yAxisLabel?:string }) => {
    const { data, xAxisLabel="Date", yAxisLabel="Progress" } = props;
    const [isGoalGraphVisible, setIsGoalGraphVisible] = useState(false);
    const globalGraphStatus = useProgressReporting()?.state?.settingsPopup?.showChart;
    const isPrintingSignal = useProgressReporting()?.state?.printingSignal;
    useEffect(() => {
        setIsGoalGraphVisible(globalGraphStatus ?? false);
    }, [globalGraphStatus])

    return (
        <>
            {!isPrintingSignal && <ProgressReportingStandardBtn onSubmit={() => { setIsGoalGraphVisible(prev => !prev) }} title={isGoalGraphVisible ? 'Hide Graph' : 'Unhide Graph'} sxOverRide={{ marginTop: "20px" }} />}
           {isGoalGraphVisible && 
                <LineChart style={{ width: "70%", height: "500px",margin:"20px" }}
                    data={data}
                    xKey="date"
                    yKey="value"
                    xLabel={xAxisLabel}
                    yLabel={yAxisLabel}
                /> }
        </>
    )
}


const ActiveGoalsAndObjectivesConditionalRenderer: React.FC<{
    isCurrentGoalOrObjectiveActive: boolean;
    children: React.ReactNode;
}> = ({ isCurrentGoalOrObjectiveActive, children }) => {
    const isActiveGoalsOrObjectivesOnly = useProgressReporting()?.state?.settingsPopup?.showActiveGoals;

    // Render children only if the settings do not require filtering by active goals or if the current goal/objective is active
    if (!isActiveGoalsOrObjectivesOnly || isCurrentGoalOrObjectiveActive) {
        return <>{children}</>; // Return children wrapped in a fragment
    }

    return null; // Otherwise return null
};





const PopupRenderer: React.FC<{ buttonName: FilterButtonTitle, children: React.ReactNode }> = ({ buttonName, children }) => {
    const renderedComponent: { [key in FilterButtonTitle]: React.ReactElement } = {
        [FilterButtonTitle.Settings]: <SettingsPopup > {children}</SettingsPopup>,
        [FilterButtonTitle.AddReportingPeriod]: <>{children}</>,
        [FilterButtonTitle.Print]: <>{children}</>,
        [FilterButtonTitle.Download]: <>{children}</>,
        [FilterButtonTitle.Post]: <>{children}</>,
        [FilterButtonTitle.Save]: <>{children}</>,

    }

    return renderedComponent[buttonName] as React.ReactElement
}




export default ProgressReportGenerated