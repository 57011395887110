import { Controller, useForm } from "react-hook-form";
import GridSectionLayout from "../../../../design/high-level/common/grid_section_layout";
import { dropDownYears, filters_form_schema, FiltersFormType, ProgressReportingFilterData } from "./progress_report_form_filter_data";
import { yupResolver } from "@hookform/resolvers/yup";
import { MSBSearchMultiselect, MSBSearchSingleSelect } from "../../../../fortitude";
import msbMUIAutoCompleteFilterOptions from "../../../../utils/msb_mui_auto_complete_filter_options";  
import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material"; 
import useProgressReporting from "./context/hooks/useProgressReporting";
import { ProgressReportingActionType } from "./context/progress_reporting_actions";
import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import { ROUTES_XLOGS } from "../../../../constants/URLs";   
import { API_STUDENT_PROGRESS_REPORT } from "../../../../api/api"; 
import { ReportRunDateAndID } from "../account_student_report/report_run_date_and_id";
import { useXNGDispatch, useXNGSelector } from "../../../../context/store";
import { selectClientID } from "../../../../context/slices/loggedInClientSlice";
import { useProgressReportPolling } from "./hooks/use_student_progress_report_polling";
import useEffectSkipMount from "../../../../hooks/use_effect_after_mount";
import FullPageLoadingScreen from "../../../../design/high-level/common/full_page_loading_screen"; 
import { PollingStatus, SummaryGenerationStatus } from "./types/progress_reporting_context_type";
import { StudentProgressReportFilterParameters } from "@xng/reporting";
import { pushError } from "../../../../context/slices/errorEntries"; 
import { progressReportTransformationPipeline } from "./utils/report_transformation_pipeline";
import { getFormattedDateTimeInUtc, ReportingPeriodWithStartEndDate, transformReportingPeriodDateRange } from "./utils/tranform_reporting_period_date_range";
import { isValidArray } from "./utils/validators";

type SessionLogsFormProps = {
  defaultOptions: ProgressReportingFilterData;
  onSubmitFormFilters: (data: FiltersFormType) => void;
};
const TIMEOUT_FOR_POLLING = 180000
const ProgressReportForm = (props: SessionLogsFormProps) => {
  const {
    control,
    watch, 
    setValue
  } = useForm<FiltersFormType>({
    resolver: yupResolver(filters_form_schema)
  });
  const { dispatch } = useProgressReporting()  
  const selected_students = watch("student"); 
  const selected_school = watch("school"); 
  const reporting_period = watch("reporting_period")
  const service_providers_selected = watch("service_providers")
  const selected_area_of_focus = watch("goals_and_objectives")
  const timePeriodToReportOn = watch("timePeriodToReportOn")
  const atleastOneStudentSelected = Array.isArray(selected_students) && selected_students.length > 0 
  const navigate = useNavigate()
  const clientId = useXNGSelector(selectClientID);
  const [studentProgressReportRunDateAndID, setStudentProgressReportRunDateAndID] = useState<ReportRunDateAndID | null>(null);  
  const pollingStatus = useProgressReporting()?.state?.pollingStatus
  const [prescedingReportingPeriods,setPrescedingReportingPeriods] = useState< {
    number?: number | undefined;
    date?: Date | undefined;
}[]>([])
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isPrescedingReportingPeriodsLoaded = Array.isArray(prescedingReportingPeriods) && prescedingReportingPeriods.length > 0
  const isAreaOfFocus = selected_area_of_focus && selected_area_of_focus.length > 0
  const atleastOnServiceProviderIsSelected = Array.isArray(service_providers_selected) && service_providers_selected.length > 0
  const isEditButtonEnabled = isAreaOfFocus && isPrescedingReportingPeriodsLoaded && selected_school && atleastOnServiceProviderIsSelected && atleastOneStudentSelected && timePeriodToReportOn && reporting_period?.date
  const buttonClickedRef = useRef<"edit" | "summary" | undefined>(undefined);
  const errorDispatcher = useXNGDispatch();
  const poll = useProgressReportPolling({ reportRunDateAndID: studentProgressReportRunDateAndID, pageParameters: { pageNumber: 1, pageSize: 100 } })
  const studentProgressReportFetched = useProgressReporting()?.state?.studentProgressReportFetched
  const [reportingPeriodWithStartEndDate,setReportingPeriodWithStartEndDate] = useState<ReportingPeriodWithStartEndDate[]>([])

  
  useEffect(()=>{
    if(buttonClickedRef.current==="edit"){
      buttonClickedRef.current = undefined
      dispatch({
        type:ProgressReportingActionType.UPDATE_SUMMARY_GENERATION_STATUS,
        payload:SummaryGenerationStatus.Idle
      }) 
    } 
  },[selected_school,service_providers_selected,reporting_period,selected_area_of_focus,selected_students,timePeriodToReportOn])

  useEffectSkipMount(() => { 
      if (poll.mutation.isError) {
        dispatch({
          type:ProgressReportingActionType.UPDATE_POLLING_STATUS,
          payload:PollingStatus.Error
        }) 
        dispatch({
          type:ProgressReportingActionType.UPDATE_SUMMARY_GENERATION_STATUS,
          payload:SummaryGenerationStatus.Error
        })
        poll.stop()
        clearTimeout(timeoutRef?.current!)
        displayError(poll.mutation.error)
        return;
      }
      
      if (!poll.result) return; 
      const result = poll.result
      dispatch({
        type:ProgressReportingActionType.SET_PROGRESS_REPORT_GENERATED_MASTER_COPY,
        payload:result
      })
      const progressReportWithEmptyReportedBy = progressReportTransformationPipeline.preGlobalStateMountChanges(result)
      dispatch({
        type:ProgressReportingActionType.UPDATE_POLLING_STATUS,
        payload:PollingStatus.Idle
      })
      dispatch({
        type:ProgressReportingActionType.SET_STUDENT_PROGRESS_REPORT_FETCHED,
        payload:{...progressReportWithEmptyReportedBy}
      }) 
      clearTimeout(timeoutRef?.current!)
      if(buttonClickedRef.current==="summary"){
        setTimeout(() => { navigate(ROUTES_XLOGS.reports.progressSummary,{replace:true,state:poll.result}) }, 500)
      }

  }, [poll.result, poll.mutation.isError]);

  useEffectSkipMount(()=>{  
      poll.start() 
      dispatch({
        type:ProgressReportingActionType.UPDATE_POLLING_STATUS,
        payload:PollingStatus.Loading
      })

      timeoutRef.current = setTimeout(() => {
        poll.stop();
        dispatch({
            type: ProgressReportingActionType.UPDATE_POLLING_STATUS,
            payload: PollingStatus.Idle,
        });
        dispatch({
            type: ProgressReportingActionType.SET_STUDENT_PROGRESS_REPORT_FETCHED,
            payload: null,
        });
        dispatch({
            type: ProgressReportingActionType.SET_TRANSFORMED_PROGRESS_REPORT,
            payload: null,
        });
        dispatch({
            type: ProgressReportingActionType.UPDATE_SUMMARY_GENERATION_STATUS,
            payload: SummaryGenerationStatus.Idle,
        }); 
    }, TIMEOUT_FOR_POLLING);
  
    return () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        poll.stop();
    };
 
  },[studentProgressReportRunDateAndID])
  
  async function displayError(arg: Error) {
    errorDispatcher(
           pushError({ 
              titleID: "Failed to fetch student progress report!", 
              message: arg?.message 
            }))
  }

  async function fetchAndSetReportRunDateAndID(filterParameters:StudentProgressReportFilterParameters ) {
    dispatch({
      type:ProgressReportingActionType.UPDATE_POLLING_STATUS,
      payload:PollingStatus.Loading
    })
    const { reportRunDate, reportRunId } =
      await API_STUDENT_PROGRESS_REPORT.v1StudentReportsStudentProgressReportQueueReportPost({
        queueStudentProgressReportPostRequest: {
          filterParameters: filterParameters
        },
      });

    if (!(reportRunDate && reportRunId)){ 
      dispatch({
        type:ProgressReportingActionType.UPDATE_POLLING_STATUS,
        payload:PollingStatus.Idle
      })
      displayError(new Error("no report rund ate and report runId found from server"))
      return;
    }
    
    setStudentProgressReportRunDateAndID({ date: reportRunDate, id: reportRunId });
  }
    
 
  useEffect(() => {
    if (timePeriodToReportOn) { 
      dispatch({
        type: ProgressReportingActionType.SET_YEAR_RANGE_FOR_CAMPUS,
        payload:timePeriodToReportOn
      })
      return 
    }
    dispatch({
      type:ProgressReportingActionType.SET_REPORTING_PERIOD,
      payload:undefined
    })
    setValue("reporting_period",{})
  }, [timePeriodToReportOn])

 useEffect(() => {
    if (selected_school) {
      // globally set selected school
      dispatch({
        type: ProgressReportingActionType.SET_CAMPUS_SELECTED_FOR_PROGRESS_REPORT_GENERATION,
        payload: selected_school
      })
      // reset service providers in dropdown
      dispatch({
        type:ProgressReportingActionType.SET_ALL_SERVICE_PROVIDERS_UNDER_SELECTED_CAMPUSES,
        payload:undefined
      })
      setValue("service_providers",[])
      setValue("student",[])
    }
  }, [selected_school])


  useEffect(()=>{
    if (reporting_period && props.defaultOptions.reportingPeriods) {
      // remove reporting periods greater than selected reportign period number
      const prescedingReportingPeriods = props.defaultOptions.reportingPeriods.filter((rp)=>(rp?.number as number )<= (reporting_period?.number as number))
      setPrescedingReportingPeriods([...prescedingReportingPeriods as {number?: number | undefined; date?: Date | undefined;}[]])
    }
  },[reporting_period,props.defaultOptions.reportingPeriods])

  const openEditSection = () => {  
        buttonClickedRef.current = "edit"  
        const studentProgressReportRunDateAndIdParams = {
          studentIds:new Set(selected_students?.map((s)=>s.id)) as Set<string>, 
          serviceProviderIds:new Set(service_providers_selected?.map((s)=>s.id)) as Set<string>,
          areaOfFocusIds: new Set(selected_area_of_focus?.map((s)=>s.id)) as Set<string>,
          clientId,
          yearRange:`${timePeriodToReportOn}`,
          reportingPeriodDates: [reportingPeriodWithStartEndDate[0].startDate as unknown as Date,...prescedingReportingPeriods.map((p)=>p.date) as Date[]], 
        }

        dispatch({
          type:ProgressReportingActionType.SET_ALL_SERVICE_PROVIDERS_USED_IN_FILTER,
          payload:service_providers_selected
        })

        dispatch({
          type:ProgressReportingActionType.SET_STUDENT_PROGRESS_REPORT_FETCHED,
          payload:undefined
        }) 
        dispatch({
          type:ProgressReportingActionType.SET_TRANSFORMED_PROGRESS_REPORT,
          payload:undefined
        }) 

        fetchAndSetReportRunDateAndID(studentProgressReportRunDateAndIdParams)
        // signal to open fetchs
        dispatch({
          type: ProgressReportingActionType.OPEN_PROGRESS_REPORTING_EDIT_SECTION,
          payload:true
        })
  }

  const handleSummaryClick = () => {
      const studentProgressReportRunDateAndIdParams = {
                    studentIds:new Set(selected_students?.map((s)=>s.id)) as Set<string>, 
                    serviceProviderIds:new Set(service_providers_selected?.map((s)=>s.id)) as Set<string>,
                    areaOfFocusIds: new Set(selected_area_of_focus?.map((s)=>s.id)) as Set<string>,
                    clientId,
                    yearRange:`${timePeriodToReportOn}`,
                    reportingPeriodDates: [reportingPeriodWithStartEndDate[0].startDate as unknown as Date,...prescedingReportingPeriods.map((p)=>p.date) as Date[]], 
              }
      if(buttonClickedRef.current === "edit" && studentProgressReportFetched){ 
                    buttonClickedRef.current = "summary" 
                    setTimeout(() => { navigate(ROUTES_XLOGS.reports.progressSummary,{state:studentProgressReportFetched}) }, 300)
        }
      buttonClickedRef.current = "summary" 
      dispatch({ type:ProgressReportingActionType.SET_STUDENT_PROGRESS_REPORT_FETCHED,  payload:undefined  }) 
      fetchAndSetReportRunDateAndID(studentProgressReportRunDateAndIdParams)
      return 
  }

 useEffect(() => {
   if(isValidArray(props.defaultOptions.reportingPeriods)&&timePeriodToReportOn){
     let transformedData = transformReportingPeriodDateRange(props.defaultOptions.reportingPeriods, timePeriodToReportOn)
     setReportingPeriodWithStartEndDate(transformedData)
   }else{
     setReportingPeriodWithStartEndDate([])
   }
 },[props.defaultOptions.reportingPeriods,timePeriodToReportOn])
  

  const additional_filters_section = (
    <GridSectionLayout
      headerConfig={{
        title: "Filter(s)",
        title_sx: {
          fontWeight: 700,
        },
      }}

      rows={[
        {
          cells: [
            <Typography sx={{ fontWeight: 700 }}>Select Students to Work On:</Typography>
          ]
        },
        {
          rowSx: {
            alignItems: "stretch",
            mb: "30px",
          },
          cellSizes: {
            xs: 12,
            sm: 8,
            lg: 4,
          },
          useCellStyling: {
            sx: {
              bgcolor: "contrasts.1",
              py: "18px",
              px: "12px",
            },
          },
          cells: [
            <Controller
              control={control}
              name="school"
              render={({ field, fieldState: { error } }) => (
                <MSBSearchSingleSelect 
                autocompleteProps={{
                  sx: { minWidth: "10rem",backgroundColor: "white" },
                  disableClearable: true, 
                }}  
                onChange={(item) => {
                  field.onChange(item);
                  dispatch({
                    type: ProgressReportingActionType.SET_CAMPUS_SELECTED_FOR_PROGRESS_REPORT_GENERATION,
                    payload: item
                  })
                }}
                label={"Campus"}   

                options={props.defaultOptions.campuses}
                selectedOption={field.value}
                getOptionLabel={(option) => option.name ?? ""} 
              /> 
              )}
            />,
            <Controller
              key={2}
              control={control}
              name={"service_providers"}
              render={({ field }) => {
                return (
                  <MSBSearchMultiselect
                    selectedOptions={field.value ?? []}
                    options={props.defaultOptions.serviceProviders}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    onChange={(serviceProvider) => field.onChange(serviceProvider)}
                    renderOptionVariant="checkbox"
                    variant="no overflow after 1"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    label="Service Provider"
                    sx={{ width: "100%", backgroundColor: "white" }}
                    autocompleteProps={{
                      disableCloseOnSelect: true,
                      filterOptions: msbMUIAutoCompleteFilterOptions(),
                    }}
                  />
                )
              }}
            />,
            <Controller
              key={1}
              control={control}
              name={"student"}
              render={({ field }) => {
                return (
                  <MSBSearchMultiselect
                    selectedOptions={field.value ?? []}
                    options={props.defaultOptions.student}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(so) => field.onChange(so)}
                    renderOptionVariant="checkbox"
                    variant="no overflow after 1"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    label="Student"
                    sx={{ width: "100%", backgroundColor: "white" }}
                    autocompleteProps={{
                      disableCloseOnSelect: true,
                      filterOptions: msbMUIAutoCompleteFilterOptions(),
                    }}
                  />
                )
              }}
            />,

          ],
        },
        {
          cells: [
            <Typography sx={{ fontWeight: 700 }}>Select Time Period to Report On: </Typography>
          ]
        },
        {
          rowSx: {
            alignItems: "stretch",
            mb: "30px",
          },
          cellSizes: {
            xs: 12,
            sm: 8,
            lg: 4,
          },
          useCellStyling: {
            sx: {
              bgcolor: "contrasts.1",
              py: "18px",
              px: "12px"
            },
          },
          cells: [
            <Controller
            control={control}
            name="timePeriodToReportOn"
            render={({ field, fieldState: { error } }) => (
              <MSBSearchSingleSelect 
                autocompleteProps={{
                  sx: { minWidth: "10rem",backgroundColor: "white" },
                  disableClearable: true, 
                }}   
                label={"School Year"}   
                onChange={(item)=>{ 
                  field.onChange(item);
              }}
                options={dropDownYears} 
                selectedOption={field.value} 
                getOptionLabel={(item) => item}
              /> 
            )}
          />, 
            <Controller
              control={control}
              name="school"
              render={({ field, fieldState: { error } }) => (
                <Autocomplete 
                disabled
                autoHighlight
                value={field.value}
                getOptionLabel={(option) => option.name ?? ""}
                options={props.defaultOptions.campuses}
                renderInput={(params) => (
                  <TextField {...params} value={field.value} label={field.value?.name??"Campus"} fullWidth size="small" />
                )}
              /> 
              )}
            />,
            <Controller
              control={control}
              name="reporting_period"
              render={({ field, fieldState: { error } }) => (

                <MSBSearchSingleSelect 
                autocompleteProps={{
                  sx: { minWidth: "10rem",backgroundColor: "white" },
                  disableClearable: true,  
                }}  
                onChange={(item) => {
                  field.onChange(item) 
                }}
                label={"Reporting Period"}  
                
                options={reportingPeriodWithStartEndDate}
                selectedOption={field.value as any}
                getOptionLabel={(option:ReportingPeriodWithStartEndDate) => `${option.number} (${getFormattedDateTimeInUtc(option.startDate).date} - ${getFormattedDateTimeInUtc(option.date as string).date})`} 
              />  
              )}
            />,  
             

          ],

        },
        {
          cells: [
            <Typography sx={{ fontWeight: 700 }}>Select Areas to Report On: </Typography>
          ]
        },
        {
          rowSx: {
            alignItems: "stretch",
            mb: "30px",
          },
          cellSizes: {
            xs: 12,
            sm: 8,
            lg: 4,
          },
          useCellStyling: {
            sx: {
              bgcolor: "contrasts.1",
              py: "18px",
              px: "12px"
            },
          },
          cells: [
            <Controller
              key={0}
              control={control}
              name={"goals_and_objectives"}
              render={({ field }) => {
                return  (
                  <MSBSearchMultiselect
                    selectedOptions={field.value ?? []}
                    options={props.defaultOptions.goalsAndObjectives}
                    getOptionLabel={(option) => `${option.name}`}
                    onChange={(so) => field.onChange(so)}
                    renderOptionVariant="checkbox"
                    variant="no overflow after 1"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    label="Area of focus"
                    sx={{ width: "100%", backgroundColor: "white" }}
                    autocompleteProps={{
                      disableCloseOnSelect: true,
                      filterOptions: msbMUIAutoCompleteFilterOptions(),
                    }}
                  />
                )  
              }}
            />
          ]
        },

        {
          rowSx: {
            mt: "3rem",
          },
          cellSizes: {
            xs: 12,
            sm: 4,
            lg: 4,
          },
          cells: [
            <Box key={0} display={"flex"} gap={2} justifyContent={"flex-start"}>
              <Button
                sx={{
                  width: "178px",
                  px: "2rem",
                  py: "1.5rem",
                }}
                type="submit"
                onClick={handleSummaryClick}
                disabled={!isEditButtonEnabled}
              >
                Summary
              </Button>
              <Button
                sx={{
                  width: "178px",
                  px: "2rem",
                  py: "1.5rem",
                }}
                disabled={!isEditButtonEnabled}
                onClick={openEditSection}
              >
                Edit
              </Button>
            </Box>,
          ],
        },


      ]}


    />
  );

  return (
    <div>
      {additional_filters_section}
      { pollingStatus === PollingStatus.Loading && <FullPageLoadingScreen text={"Please wait"} pulse />}
    </div>
  )
}

export default ProgressReportForm