import { useQueryClient } from "@tanstack/react-query";
import useUnpostedSessionsMatchPath from "./use_unposted_sessions_match_path";
import { QUERY_KEYS } from "../../../api/constants/query_keys";

const useRefetchUnpostedSessionsRequests = () => {
  const queryClient = useQueryClient();
  const unpostedSessionsViewMatcth = useUnpostedSessionsMatchPath();

  function refetchUnpostedSessionsCount() {
    queryClient.refetchQueries({
      queryKey: QUERY_KEYS.v1SessionsUnpostedCountGet,
    });
  }

  function refetchSlimCards() {
    queryClient.refetchQueries({
      queryKey: QUERY_KEYS.v1SessionsSlimGet,
    });
  }

  function refetchUser() {
    queryClient.refetchQueries({
      queryKey: QUERY_KEYS.v1UsersIdGet,
    });
  }

  // If we are currently in the notator view, refetch the unposted sessions count, slim cards, and user
  function refetchOnSessionAction() {
    refetchUnpostedSessionsCount();
    refetchSlimCards();
  }

  // NOTE: Per Michael's suggestion remove this in future development
  function refetchOnCaseloadAction() {
    refetchUnpostedSessionsCount();
    refetchUser();
    if (unpostedSessionsViewMatcth.customId !== "none") {
      refetchSlimCards();
    }
  }

  return {
    inUnpostedSessionsView: unpostedSessionsViewMatcth.customId !== "none",
    refetchOnSessionAction,
    refetchOnCaseloadAction,
  };
};

export default useRefetchUnpostedSessionsRequests;
