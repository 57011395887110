import { SessionStatus } from "@xng/reporting";
import { useEffect, useMemo, useRef } from "react";
import removeArrayDuplicates from "../../../utils/remove_array_duplicates";
import useApiQueryUnpostedSessionsCount from "./api/use_api_query_unposted_sessions_count";
import useApiQueryUnpostedSessionsData from "./api/use_api_query_unposted_sessions_data";
import useUnpostedSessionsMatchPath from "./use_unposted_sessions_match_path";
import { selectServiceProviderProfile } from "../../../context/slices/loggedInClientSlice";
import { selectUser } from "../../../context/slices/userProfileSlice";

type Props = {
  clientId: string | undefined;
  stateInUs: string;
  unpostedSessionsMatchPath: ReturnType<typeof useUnpostedSessionsMatchPath>;
  reduxUser: ReturnType<typeof selectUser>;
  loggedinServiceProvider: ReturnType<typeof selectServiceProviderProfile>;
  onInitMySessionsState?: () => void;
  onUpdateMySessionsState?: () => void;
  onInitDecSessionsState?: () => void;
  onUpdateDecSessionsState?: () => void;
  onInitAssistantsSessionsState?: () => void;
  onUpdateAssistantsSessionsState?: () => void;
};

const useUnpostedSessionsApiClientManagersAndProviderCaseload = (props: Props) => {
  const { clientId, stateInUs, unpostedSessionsMatchPath, reduxUser, loggedinServiceProvider } =
    props;

  const initializedMySessionsStateRef = useRef(false);
  const initializedDecSessionsStateRef = useRef(false);
  const initializedAssistantsSessionsStateRef = useRef(false);

  const loggedInClientAssignment = useMemo(() => {
    if (reduxUser?.clientAssignments) {
      return reduxUser?.clientAssignments?.find((assignment) => assignment.client?.id === clientId);
    }
    return undefined;
  }, [clientId, reduxUser?.clientAssignments]);

  const appointingServiceProvidersCaseload = loggedInClientAssignment?.appointingServiceProviders; //providers you're posting on behalf of as a DEC
  const appointingServiceProviders = useMemo(() => {
    if (appointingServiceProvidersCaseload) {
      return removeArrayDuplicates(appointingServiceProvidersCaseload, () => "id");
    }
    return [];
  }, [appointingServiceProvidersCaseload]);

  const supervisedServiceProvidersCaseload = loggedInClientAssignment?.supervisedServiceProviders; //providers documenting on your behalf as Assistants
  const supervisedServiceProviders = useMemo(() => {
    if (supervisedServiceProvidersCaseload) {
      return removeArrayDuplicates(supervisedServiceProvidersCaseload, () => "id");
    }
    return [];
  }, [supervisedServiceProvidersCaseload]);

  const myUnpostedSessionsApiQueryClientManager = useApiQueryUnpostedSessionsData({
    queryParams: {
      clientId: clientId ?? "",
      serviceProviderIds: loggedinServiceProvider?.id ?? "",
      statuses: [SessionStatus.NUMBER_0, SessionStatus.NUMBER_1, SessionStatus.NUMBER_3].join(","),
      state: stateInUs,
    },
  });

  const decUnpostedSessionsApiQueryClientManager = useApiQueryUnpostedSessionsData({
    queryParams: {
      clientId: clientId ?? "",
      serviceProviderIds: Array.from(
        new Set([loggedinServiceProvider, ...appointingServiceProviders]?.map((provider) => provider?.id)),
      ).join(","),
      statuses: [
        SessionStatus.NUMBER_0,
        SessionStatus.NUMBER_1,
        SessionStatus.NUMBER_2,
        SessionStatus.NUMBER_3,
      ].join(","),
      state: stateInUs,
    },
    options: {
      enabled:
        !!clientId &&
        !!appointingServiceProviders?.length &&
        (unpostedSessionsMatchPath.customId === "dec-sessions" ||
          unpostedSessionsMatchPath.customId === "notator"),
    },
  });

  const assistantsUnpostedSessionsApiQueryClientManager = useApiQueryUnpostedSessionsData({
    queryParams: {
      clientId: clientId ?? "",
      serviceProviderIds: Array.from(
        new Set(supervisedServiceProviders?.map((provider) => provider?.id)),
      ).join(","),
      statuses: [SessionStatus.NUMBER_2].join(","),
      state: stateInUs,
    },
    options: {
      enabled:
        !!clientId &&
        !!supervisedServiceProviders?.length &&
        (unpostedSessionsMatchPath.customId === "assistant-sessions" ||
          unpostedSessionsMatchPath.customId === "notator"),
    },
  });

  const unpostedSessionsCountApiQueryClientManager = useApiQueryUnpostedSessionsCount({
    queryParams: {
      clientId: clientId ?? "",
      state: stateInUs,
      userId: reduxUser?.id ?? "",
    },
  });

  function initializeSessionsState(props: {
    initDependecies: {
      hasInitialized: boolean;
      isFetched: boolean;
      dataAvailable: boolean;
    };
    onInit?: () => void;
  }) {
    const { hasInitialized, isFetched, dataAvailable } = props.initDependecies;
    if (!hasInitialized && isFetched && dataAvailable) {
      props.onInit?.();
    }
  }

  function updateSessionsState(props: {
    updateDependecies: {
      isFetching: boolean;
      isFetched: boolean;
      hasInitialized: boolean;
    };
    onUpdate?: () => void;
  }) {
    const { isFetching, isFetched, hasInitialized } = props.updateDependecies;

    if (isFetched && !isFetching && hasInitialized) {
      props.onUpdate?.();
    }
  }

  // MY SESSIONS SIDE EFFECTS
  // Initialize when initial data is ready
  useEffect(() => {
    initializeSessionsState({
      initDependecies: {
        hasInitialized: initializedMySessionsStateRef.current,
        isFetched: myUnpostedSessionsApiQueryClientManager.isFetched,
        dataAvailable: !!myUnpostedSessionsApiQueryClientManager.data,
      },
      onInit: () => {
        props.onInitMySessionsState?.();
        initializedMySessionsStateRef.current = true;
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myUnpostedSessionsApiQueryClientManager.isSuccess]);

  // update state when re-fetching
  useEffect(() => {
    updateSessionsState({
      updateDependecies: {
        isFetching: myUnpostedSessionsApiQueryClientManager.isRefetching,
        isFetched: myUnpostedSessionsApiQueryClientManager.isFetched,
        hasInitialized: initializedMySessionsStateRef.current,
      },
      onUpdate: () => {
        props.onUpdateMySessionsState?.();
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myUnpostedSessionsApiQueryClientManager.fetchStatus]);

  // DEC SESSIONS SIDE EFFECTS
  useEffect(() => {
    initializeSessionsState({
      initDependecies: {
        hasInitialized: initializedDecSessionsStateRef.current,
        isFetched: decUnpostedSessionsApiQueryClientManager.isFetched,
        dataAvailable: !!decUnpostedSessionsApiQueryClientManager.data,
      },
      onInit: () => {
        props.onInitDecSessionsState?.();
        initializedDecSessionsStateRef.current = true;
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decUnpostedSessionsApiQueryClientManager.isSuccess]);

  useEffect(() => {
    updateSessionsState({
      updateDependecies: {
        isFetching: decUnpostedSessionsApiQueryClientManager.isRefetching,
        isFetched: decUnpostedSessionsApiQueryClientManager.isFetched,
        hasInitialized: initializedDecSessionsStateRef.current,
      },
      onUpdate: () => {
        props.onUpdateDecSessionsState?.();
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decUnpostedSessionsApiQueryClientManager.fetchStatus]);

  // MY ASSISTANTS SESSIONS SIDE EFFECTS
  useEffect(() => {
    initializeSessionsState({
      initDependecies: {
        hasInitialized: initializedAssistantsSessionsStateRef.current,
        isFetched: assistantsUnpostedSessionsApiQueryClientManager.isFetched,
        dataAvailable: !!assistantsUnpostedSessionsApiQueryClientManager.data,
      },
      onInit: () => {
        props.onInitAssistantsSessionsState?.();
        initializedAssistantsSessionsStateRef.current = true;
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assistantsUnpostedSessionsApiQueryClientManager.isSuccess]);

  useEffect(() => {
    updateSessionsState({
      updateDependecies: {
        isFetching: assistantsUnpostedSessionsApiQueryClientManager.isRefetching,
        isFetched: assistantsUnpostedSessionsApiQueryClientManager.isFetched,
        hasInitialized: initializedAssistantsSessionsStateRef.current,
      },
      onUpdate: () => {
        props.onUpdateAssistantsSessionsState?.();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assistantsUnpostedSessionsApiQueryClientManager.fetchStatus]);

  return {
    // userApiQueryClientManager,
    myUnpostedSessionsApiQueryClientManager,
    decUnpostedSessionsApiQueryClientManager,
    assistantsUnpostedSessionsApiQueryClientManager,
    unpostedSessionsCountApiQueryClientManager,
    appointingServiceProviders,
    supervisedServiceProviders,
  };
};

export default useUnpostedSessionsApiClientManagersAndProviderCaseload;
