import { StudentProgressReportRecordUI, StudentProgressReportRecordUIPaginatedReportResponse } from "@xng/reporting";
import { ExtendedObjectiveWithAggregatedProgressField, ExtendedStudentProgressDetailsWithAggregatedProgress, ExtendedStudentProgressReportPeriodDetailsWithIsPostedAttribute, ExtendedStudentProgressReportRecordUIPaginatedReportResponse, ExtendedStudentProgressReportRecordUIWithIsPosted, GroupedStudentReport, ObjectiveWithExtendedGoalProgressDetailsWithIsPostedStatus, StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI } from "../types/extended_progress_repoting_types";
import {  GoalOrObjectiveStatus, ReportStatus } from "../context/reducer/progress_reporting_reducer"; 

export class ReportTransformationPipeline {
 
    public static getInstance(): ReportTransformationPipeline {
        return new ReportTransformationPipeline();
    }


    public groupProgressReportByStudentsFindByServiceProviderId(
        reports: ExtendedStudentProgressReportRecordUIWithIsPosted[],
        selectedServiceProviderId: string
      ): GroupedStudentReport[] { 
        reports = reports.filter((report) => report.serviceProviderId === selectedServiceProviderId);
        let reportWithMissingAttributes:StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI[] = this.addMissingAttributesForStudentProgressReportPeriodDetailsWithAggregatedProgress(reports);
        return reportWithMissingAttributes.reduce((acc: GroupedStudentReport[], curr: StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI) => {
          const studentName = curr.studentName;
      
          if (!studentName) return acc; // Skip if studentName is null or undefined
      
          const existing = acc.find(
            (report) => report.studentName === studentName
          );
      
          if (existing) {
            // If student already exists, add the current report to the existing group
            existing.allStudentProgressReportUnderCurrentStudent = [
              ...existing.allStudentProgressReportUnderCurrentStudent,
              curr,
            ];
          } else {
            // If student does not exist, create a new group with the current report
            acc.push({
              ...curr,
              allStudentProgressReportUnderCurrentStudent: [curr], // Initialize with the current report
            });
          }
      
          return acc;
        }, []);
      }
    private _groupProgressReportByStudentsForAggregatedReport(arg:StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI[]): GroupedStudentReport[]{
      return arg.reduce((acc: GroupedStudentReport[], curr: StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI) => {
        const studentName = curr.studentName;
    
        if (!studentName) return acc; // Skip if studentName is null or undefined
    
        const existing = acc.find(
          (report) => report.studentName === studentName
        );
    
        if (existing) {
          // If student already exists, add the current report to the existing group
          existing.allStudentProgressReportUnderCurrentStudent = [
            ...existing.allStudentProgressReportUnderCurrentStudent,
            curr,
          ];
        } else {
          // If student does not exist, create a new group with the current report
          acc.push({
            ...curr,
            allStudentProgressReportUnderCurrentStudent: [curr], // Initialize with the current report
          });
        }
    
        return acc;
      }, []);
    }
    
    public getAggregatedProgressReportGrouped(reports: ExtendedStudentProgressReportRecordUIWithIsPosted[],masteryReportCopy:StudentProgressReportRecordUI[]): GroupedStudentReport[]{
          let result = new Set<StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI>()
          reports.forEach((report)=>{
              let aggregatedProgress = this.getAggregatedProgressReportForEachStudent(reports,report?.goal?.goalId as string,report.studentName as string,masteryReportCopy)
              aggregatedProgress.forEach((progressReport) => {
                result.add(progressReport);
            });
          })
          let data = Array.from(result)

          const uniqueData = data.reduce((accumulator:StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI[], current) => {
            const found = accumulator.find(item => item?.goal.goalId === current?.goal.goalId);
            if (!found) {
              accumulator.push(current);
            }
            return accumulator;
          }, []);

          return this._groupProgressReportByStudentsForAggregatedReport(uniqueData) 
    }

    public getAggregatedProgressReportForGoalAndObjectivesInEachStudent(reports:ExtendedStudentProgressReportRecordUIWithIsPosted[],progressReportMasteryCopy:StudentProgressReportRecordUI[]):GroupedStudentReport[]{
      const result:GroupedStudentReport[] = []
      return result    
    }
    

    public getAggregatedProgressReportForEachStudent(reports: ExtendedStudentProgressReportRecordUIWithIsPosted[],goalId:string,studentName:string,masteryReportCopy:StudentProgressReportRecordUI[]):StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI[]{
        let reportsFilteredByGoalId = reports.filter((report)=> report?.goal?.goalId === goalId && report.studentName === studentName)
        let masteryFilteredByGoalId = masteryReportCopy.filter((report)=> report?.goal?.goalId === goalId && report.studentName === studentName)
        let reportsWithMissingAttributes = this.addMissingAttributesForStudentProgressReportPeriodDetailsWithAggregatedProgress(reportsFilteredByGoalId)
        const goalProgressReportedLookUp = new Map<string,Array<number>>()
        const objectiveProgressReportedLookUp = new Map<string,Array<number>>()
        const generateId = (indexPositionOrObjectiveId:number|string,period:number,periodStartDate:string,periodEndDate:string) => `${indexPositionOrObjectiveId}-${period}-${periodStartDate}-${periodEndDate}`
        reportsWithMissingAttributes.forEach((report,reportIndexPosition)=>{
            // calculate aggregated progress for goals
            let masteryReport = masteryFilteredByGoalId[reportIndexPosition]
      
            // aggregate goal
            report?.goal?.goalProgressDetails?.forEach((goalProgressDetail,goalProgressIndexPosition)=>{
                const masteryReportCurrentGoalProgress = masteryReport?.goal?.goalProgressDetails?.[goalProgressIndexPosition]
                const uniqueIdentifier = generateId(
                  goalProgressIndexPosition,
                  goalProgressDetail.period as number,
                  goalProgressDetail?.periodStartDate?.toISOString() as string,
                  goalProgressDetail?.periodEndDate?.toISOString() as string)

                if(!goalProgressReportedLookUp.has(uniqueIdentifier) && (masteryReportCurrentGoalProgress?.status?.toLocaleLowerCase() === "posted")){
                    goalProgressReportedLookUp.set(uniqueIdentifier,[goalProgressDetail.progressMeasured ?? 0])
                }else if(goalProgressReportedLookUp.has(uniqueIdentifier) && (masteryReportCurrentGoalProgress?.status?.toLocaleLowerCase() === "posted")){
                    const currentProgress:number[] = goalProgressReportedLookUp.get(uniqueIdentifier) as number[]
                    goalProgressReportedLookUp.set(uniqueIdentifier,[...currentProgress,goalProgressDetail.progressMeasured ?? 0])
                }
            })
            // calculate aggregated progress for objectives
            report.objectives?.forEach((objective,objectiveIndexPosition)=>{
              const masteryObjective = masteryReport?.objectives?.[objectiveIndexPosition]
              objective.objectiveProgressDetails?.forEach((objectiveProgressDetail,objectiveProgressIndexPosition)=>{
                const masteryObjectiveProgress = masteryObjective?.objectiveProgressDetails?.[objectiveProgressIndexPosition]
                const uniqueIdentifier = generateId(
                  objective.objectiveId as string,
                  objectiveProgressDetail.period as number,
                  objectiveProgressDetail?.periodStartDate?.toISOString() as string,
                  objectiveProgressDetail?.periodEndDate?.toISOString() as string
                ) 
                if(!objectiveProgressReportedLookUp.has(uniqueIdentifier) && masteryObjectiveProgress?.status?.toLocaleLowerCase() === "posted"){
                  objectiveProgressReportedLookUp.set(uniqueIdentifier,[objectiveProgressDetail.progressMeasured ?? 0])
                }else if(objectiveProgressReportedLookUp.has(uniqueIdentifier) && masteryObjectiveProgress?.status?.toLocaleLowerCase() === "posted"){
                  const currentProgress:number[] = objectiveProgressReportedLookUp.get(uniqueIdentifier) as number[]
                  objectiveProgressReportedLookUp.set(uniqueIdentifier,[...currentProgress,objectiveProgressDetail.progressMeasured ?? 0])
              }
              }) 
                
            })
        })

      const aggregatedResult:StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI[] = reportsWithMissingAttributes.map((report)=>{
          
            

            
            return {...report,
                        goal:{
                              ...report.goal,
                              goalProgressDetails:report?.goal?.goalProgressDetails?.map((goalProgressDetail,goalProgressIndex)=>{
                                const uniqueIdentifier = generateId(
                                  goalProgressIndex,
                                  goalProgressDetail.period as number,
                                  goalProgressDetail?.periodStartDate?.toISOString() as string,
                                  goalProgressDetail?.periodEndDate?.toISOString() as string)  
                                if(goalProgressReportedLookUp.has(uniqueIdentifier)){
                                    const lengthOfProgressArray = goalProgressReportedLookUp.get(uniqueIdentifier)?.length ?? 0
                                    const totalProgress = goalProgressReportedLookUp.get(uniqueIdentifier)?.reduce((a,b)=>a+b,0) ?? 0
                                    
                                    return {
                                        ...goalProgressDetail,
                                          generalComments:"",
                                          status: ReportStatus.Unposted,
                                          aggregatedProgress : totalProgress / lengthOfProgressArray,
                                          progressReportedBy:""
                                    }
                                }else {
                                  return {
                                    ...goalProgressDetail,
                                      generalComments:"",
                                      status: ReportStatus.Unposted,
                                      aggregatedProgress : 0,
                                      progressReportedBy:""
                                  }
                                } 
                            })
                        },
                        objectives:report.objectives?.map((objective)=>{
                          return{
                            ...objective,
                            objectiveProgressDetails:objective.objectiveProgressDetails.map((individualObjectiveProgress)=>{
                                  const uniqueIdentifier = generateId(
                                    objective.objectiveId as string,
                                    individualObjectiveProgress.period as number,
                                    individualObjectiveProgress?.periodStartDate?.toISOString() as string,
                                    individualObjectiveProgress?.periodEndDate?.toISOString() as string)
                                  if(objectiveProgressReportedLookUp.has(uniqueIdentifier)){
                                      const lengthOfProgressArray = objectiveProgressReportedLookUp.get(uniqueIdentifier)?.length ?? 0
                                      const totalProgress = objectiveProgressReportedLookUp.get(uniqueIdentifier)?.reduce((a,b)=>a+b,0) ?? 0 
                                      return {
                                         ...individualObjectiveProgress,
                                         aggregatedProgress: totalProgress / lengthOfProgressArray,
                                         generalComments:"",
                                         status: ReportStatus.Unposted,
                                         progressReportedBy:""
                                      }
                                  }else {
                                      return {
                                          ...individualObjectiveProgress,
                                          aggregatedProgress: 0,
                                          generalComments:"",
                                          status: ReportStatus.Unposted,
                                          progressReportedBy:""
                                      }
                                  } 
                          })}
                      })
                        
            }
        }) 
      return aggregatedResult
       
    }



 

    public preGlobalStateMountChanges(arg:StudentProgressReportRecordUIPaginatedReportResponse):ExtendedStudentProgressReportRecordUIPaginatedReportResponse{
 
      return  {
        ...arg,
        pageRecords: arg?.pageRecords?.map((pageRecord):ExtendedStudentProgressReportRecordUIWithIsPosted => ({
          ...pageRecord,
          goal:{
            ...pageRecord.goal,
            goalStatus: pageRecord.goal?.goalStatus as GoalOrObjectiveStatus,
            goalProgressDetails:  pageRecord.goal?.goalProgressDetails?.map((individualGoalProgress):ExtendedStudentProgressReportPeriodDetailsWithIsPostedAttribute => ({
              ...individualGoalProgress,
              progressReportedBy: "",
              status: ReportStatus.Unposted,
              isPosted: ReportStatus.Unposted
            } as ExtendedStudentProgressReportPeriodDetailsWithIsPostedAttribute) ) as ExtendedStudentProgressReportPeriodDetailsWithIsPostedAttribute[]
          },
          objectives: pageRecord.objectives?.map((objective):ObjectiveWithExtendedGoalProgressDetailsWithIsPostedStatus =>{
               return {
                 ...objective,  
                 goalStatus: objective?.goalStatus as GoalOrObjectiveStatus,
                 objectiveProgressDetails: objective?.objectiveProgressDetails?.map((objectiveProgress):ExtendedStudentProgressReportPeriodDetailsWithIsPostedAttribute=>({
                  ...objectiveProgress,
                  status: ReportStatus.Unposted,
                  isPosted: ReportStatus.Unposted,
                  progressReportedBy: ""
                   
                })) as ExtendedStudentProgressReportPeriodDetailsWithIsPostedAttribute[]
               }}) as ObjectiveWithExtendedGoalProgressDetailsWithIsPostedStatus[] 
        })) as ExtendedStudentProgressReportRecordUIWithIsPosted[]
      };
    }


    public addMissingAttributesForStudentProgressReportPeriodDetailsWithAggregatedProgress(arg:ExtendedStudentProgressReportRecordUIWithIsPosted[]):StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI[]{
      return arg.map((record:ExtendedStudentProgressReportRecordUIWithIsPosted):StudentProgressReportPeriodDetailsWithAggregatedProgressReportRecordUI => {
             return {
                  ...record, 
                  goal:{
                    ...record.goal,
                    goalProgressDetails:  record.goal?.goalProgressDetails?.map((individualGoalProgress):ExtendedStudentProgressDetailsWithAggregatedProgress => ({
                      ...individualGoalProgress,
                      aggregatedProgress: 0 
                    })) 
                  },
                  objectives: record.objectives?.map((objective):ExtendedObjectiveWithAggregatedProgressField =>{
                      return {
                         ...objective,
                         objectiveProgressDetails:  objective.objectiveProgressDetails?.map((individualObjectiveProgress):ExtendedStudentProgressDetailsWithAggregatedProgress => ({
                          ...individualObjectiveProgress,
                          aggregatedProgress: 0
                         }) 
                        )
                      }
                  }) 
                }
      })
 }

      
}


export const progressReportTransformationPipeline = ReportTransformationPipeline.getInstance();