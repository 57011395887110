import { ReportStatus } from "../context/reducer/progress_reporting_reducer";
import { ExtendedStudentProgressDetailsWithAggregatedProgress } from "../types/extended_progress_repoting_types";
import { getProgress } from "./progress_helper";
import { isValidArray } from "./validators";
import { FormattedStudentReport } from "./xlsx_helper";

export function generateCsvReport(data: FormattedStudentReport[], isTeamAggregated: boolean, isLastSessionData: boolean) {
  let csvContent = "";

  // Iterate over each student's report
  data.forEach(studentReport => {
      // Add student name as a header for the report
      csvContent += `"Student Name: ${studentReport.studentName}"\n`;

      // Add headers for Goal Progress
      studentReport.goalNameWithGoalProgressReport.forEach(goal => {
          csvContent += `"Goal Area: ${(goal.goalArea ?? '-')}"\n`; // Goal Area
          csvContent += `"Goal Description: ${(goal.goalDescription ?? '-')}"\n`; // Goal Description
          csvContent += [
              'Period Start',
              'Period End',
              'Progress Measured',
              'Comments',
              'Reported By'
          ].join(",") + "\n";

          // Add Goal Progress Rows
          goal.goalProgressDetails?.forEach(goalProgress => {
              if (goalProgress?.status?.toLocaleLowerCase() !== ReportStatus.Posted) return;
              csvContent += [
                  `"${goalProgress.periodStartDate || '-'}"`, // Period Start
                  `"${goalProgress.periodEndDate || '-'}"`,   // Period End
                  `"${getProgress(isTeamAggregated, isLastSessionData, goalProgress)}%"`, // Progress Measured
                  `"${goalProgress.generalComments || '-'}"`,  // Comments
                  `"${goalProgress.progressReportedBy || '-'}"` // Reported By
              ].join(",") + "\n";
          });
          csvContent += "\n";
      });

    const validObjectives = studentReport.objectiveNameWithObjectiveProgressReport.filter(objective => {
        const postedObjectives = objective.objectiveProgressDetails?.filter(objectiveProgress => 
            objectiveProgress?.status?.toLocaleLowerCase() === ReportStatus.Posted
        );
        return isValidArray(objective.objectiveProgressDetails) && isValidArray(postedObjectives);
    });
 
      if(isValidArray(validObjectives)){
          csvContent += "\nObjective Progress\n";
          // Add Objective Progress Rows
          studentReport.objectiveNameWithObjectiveProgressReport.forEach(objective => {
              const postedObjectives = objective.objectiveProgressDetails?.filter((objectiveProgress) => objectiveProgress.status?.toLocaleLowerCase() === ReportStatus.Posted)
              if(!isValidArray(objective.objectiveProgressDetails)||!isValidArray(postedObjectives) ){
                csvContent.replace("\nObjectives Progress\n","") 
                return;
            }
              csvContent += `"Objective: ${(objective.objectiveArea ?? '-')}"\n`;
              csvContent += `"Objective Description: ${(objective.objectiveDescription ?? '-')}"\n`;
              csvContent += [
                  'Period Start',
                  'Period End',
                  'Progress Measured',
                  'Comments',
                  'Reported By'
              ].join(",") + "\n";
    
              // Add Objective Progress Rows
              objective.objectiveProgressDetails?.forEach(objectiveProgress => {
                  if (objectiveProgress?.status?.toLocaleLowerCase() !== ReportStatus.Posted) return;
                  csvContent += [
                      `"${objectiveProgress.periodStartDate || '-'}"`, // Period Start
                      `"${objectiveProgress.periodEndDate || '-'}"`,   // Period End
                      `"${getProgress(isTeamAggregated, isLastSessionData, objectiveProgress as ExtendedStudentProgressDetailsWithAggregatedProgress)}%"`, // Progress Measured
                      `"${objectiveProgress.generalComments || '-'}"`,  // Comments
                      `"${objectiveProgress.progressReportedBy || '-'}"` // Reported By
                  ].join(",") + "\n";
              });
              csvContent += "\n";
          });
    
          // Add a blank line between students
          csvContent += "\n\n";
      }

  });

  // Create Blob from CSV content and trigger download
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  return blob;
}

  