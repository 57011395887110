import React, { createContext, useMemo, useState } from "react";
import {
  useApiGetMutateSessionLogsSummary,
  useApiMutatePollSessionLogsReport,
} from "../hooks";
import { useMatch, useNavigate } from "react-router";
import { SessionLogsContextState } from "../types";
import { useXNGSelector } from "../../../../../context/store";
import {
  selectLoggedInClientAssignment,
  selectUser,
} from "../../../../../context/slices/userProfileSlice";
import { selectStateInUS } from "../../../../../context/slices/stateInUsSlice";
import useUserRoles from "../../../../../hooks/use_user_roles";
import { ROUTES_XLOGS } from "../../../../../constants/URLs";
import QueryStatusModal from "../../../../../design/modal_templates/query_status_modal";

export const SessionLogsProvider = ({ children }: { children: React.ReactNode }) => {
  const loggedInClientAssignment = useXNGSelector(selectLoggedInClientAssignment);
  const clientId = loggedInClientAssignment.client?.id;
  const authorizedDistricts = loggedInClientAssignment.authorizedDistricts;
  const stateInUs = useXNGSelector(selectStateInUS);
  const navigate = useNavigate();
  const userProfile = useXNGSelector(selectUser);
  const userRoles = useUserRoles();
  const inSessionLogsView = useMatch(ROUTES_XLOGS.reports.sessionLogs);
  const mutationOptions = {
    retry: 3,
    retryDelay: 1000,
    gcTime: 1000 * 60 * 60 * 5, // 5 hours
  };

  const sessionLogsSummaryApiClientManager = useApiGetMutateSessionLogsSummary({
    options: mutationOptions,
  });

  const [openSessionLogsReportQueryStatusModal, setOpenSessionLogsReportQueryStatusModal] =
    useState(false);

  const pollSessionLogsReportApiClientManager = useApiMutatePollSessionLogsReport({
    options: {
      ...mutationOptions,
      onMutate: () => {
        // Open the modal only if the first time we are polling for the report, every other time represents sorting and pagination
        if (inSessionLogsView) {
          setOpenSessionLogsReportQueryStatusModal(true);
        }
      },
      onSuccess() {
        setTimeout(() => {
          navigate(ROUTES_XLOGS.reports.serviceReport);
        }, 1000);
      },
    },
  });

  const [selectedStudentsIds, setSelectedStudentsIds] = React.useState<string[]>([]);

  function setSelectedStudentsIdsHandler(selectedStudents: string[]) {
    setSelectedStudentsIds(selectedStudents);
  }

  const contextValue: SessionLogsContextState = useMemo(() => {
    return {
      appState: {
        clientId: clientId ?? "",
        stateInUs: stateInUs,
        authorizedDistricts: authorizedDistricts ?? [],
        userProfile: userProfile ?? {},
        userRoles,
        loggedInClientAssignment,
      },
      sessionLogsSummaryState: {
        sessionLogsSummaryApiClientManager,
        selectedStudentsIds,
        setSelectedStudentsIdsHandler,
      },
      sessionLogsReportState: {
        pollSessionLogsReportApiClientManager,
      },
    };
  }, [
    authorizedDistricts,
    clientId,
    loggedInClientAssignment,
    pollSessionLogsReportApiClientManager,
    selectedStudentsIds,
    sessionLogsSummaryApiClientManager,
    stateInUs,
    userProfile,
    userRoles,
  ]);

  return (
    <SessionLogsContext.Provider value={contextValue}>
      {
        <>
          {children}
          <QueryStatusModal
            isOpen={openSessionLogsReportQueryStatusModal}
            status={pollSessionLogsReportApiClientManager.status}
            onSettledClose={() => {
              setOpenSessionLogsReportQueryStatusModal(false);
            }}
            content={{
              successTitle: "Success!",
              successBody: "Session logs report generated successfully",
              errorTitle: "Error!",
              errorBody: "Problem generating session logs report, please try again.",
              pendingTitle: "Please wait, Generating Session Logs Report...",
            }}
          />
        </>
      }
    </SessionLogsContext.Provider>
  );
};

export const SessionLogsContext = createContext({} as SessionLogsContextState);
