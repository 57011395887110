import { useForm } from "react-hook-form";
import { getSizing } from "../../../design/sizing";
import dayjs from "dayjs";
import { SessionResponse, UpdateSessionRequest, Location } from "../../../session-sdk";
import { useXNGSelector } from "../../../context/store";
import { selectStateInUS } from "../../../context/slices/stateInUsSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { timezoneAdjustedStartOrEndTimes } from "../../../utils/timeZones";
import { useNotatorTools } from "../tools";
import { produce } from "immer";
import { EDIT_SESSION_METADATA_MODAL_VALIDATION_SCHEMA } from "../../../constants/edit_session_metadata_modal_validation_schema";
import EditSessionFormWrapper from "../../modals/edit_session_form_wrapper";
import { SchedulerFieldValues } from "../../modals/types";

interface EditSessionMetadataModalProps {
  open: boolean;
  startDate: Date;
  endDate: Date;
  onClose: () => void;
  editedSession?: SessionResponse;
  saveSession: (freshSession: SessionResponse) => Promise<SessionResponse>;
}

/**
 * TODO: Refactor to use plain MUI components
 */
function EditSessionMetadataModal(props: Readonly<EditSessionMetadataModalProps>) {
  const { open, startDate, endDate, onClose, editedSession, saveSession } = props;
  const { setDraftSession } = useNotatorTools();

  // REDUX SELECTORS
  const userStateInUS = useXNGSelector(selectStateInUS);

  // REACT HOOK FORM
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<SchedulerFieldValues>({
    resolver: yupResolver(EDIT_SESSION_METADATA_MODAL_VALIDATION_SCHEMA),
  });

  // STYLE CONSTANTS
  const GAP = getSizing(2);

  const onEditSubmit = async (data: SchedulerFieldValues) => {
    // Timezone adjustments
    const { timezoneAdjustedStartTime, timezoneAdjustedEndTime } = timezoneAdjustedStartOrEndTimes(
      userStateInUS,
      "data",
      data.startTime,
      data.endTime,
    );

    const updateSessionRequest: UpdateSessionRequest = {
      id: props.editedSession!.id,
      title: data.title,
      service: {
        id: data.service.id,
        name: data.service.name,
        description: data.service.description,
        area: data.service.area,
        type: data.service.serviceType,
      },
      groupSetting: data.sessionType === "group",
      meetingDetails: {
        date: dayjs(data.startTime).startOf("day").toDate(),
        startTime: timezoneAdjustedStartTime as Date,
        endTime: timezoneAdjustedEndTime as Date,
        location: { name: data.location, description: data.locationDescription } as Location, // Todo: ID later
      },
      studentJournalList: props.editedSession!.studentJournalList?.map((item) => ({
        ...item,
        studentAttendanceRecord: {
          ...item.studentAttendanceRecord,
          arrivalTime: timezoneAdjustedStartTime as Date,
          departureTime: timezoneAdjustedEndTime as Date,
        },
      })),
    };

    const updatedSession = produce(props.editedSession || {}, (draft) => {
      draft = Object.assign(draft, updateSessionRequest);
    });

    setDraftSession(updatedSession);
    saveSession(updatedSession).then(() => {
      reset();
    });

    props.onClose();
  };

  const editSessionFormWrapperProps = {
    open,
    startDate,
    endDate,
    onClose,
    editedSession,
    submitCallback: onEditSubmit,
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
    setValue,
  };

  return <EditSessionFormWrapper modalType="metadata" {...editSessionFormWrapperProps} />;
}

export default EditSessionMetadataModal;
