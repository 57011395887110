import { useForm } from "react-hook-form";
import { selectStateInUS } from "../../context/slices/stateInUsSlice";
import { useXNGSelector } from "../../context/store";
import { SessionResponse, UpdateSessionRequest, Location } from "../../session-sdk";
import { timezoneAdjustedStartOrEndTimes } from "../../utils/timeZones";
import { SchedulerFieldValues } from "./types";
import dayjs from "dayjs";
import { produce } from "immer";
import { yupResolver } from "@hookform/resolvers/yup";
import EditSessionFormWrapper from "./edit_session_form_wrapper";
import { EDIT_SESSION_METADATA_MODAL_VALIDATION_SCHEMA } from "../../constants/edit_session_metadata_modal_validation_schema";

interface EditTodaysSessionModalProps {
  open: boolean;
  startDate: Date;
  endDate: Date;
  onClose: () => void;
  editedSession?: SessionResponse;
  saveSession: (freshSession: SessionResponse) => Promise<SessionResponse | void>;
  setDraftSession?: (value: React.SetStateAction<SessionResponse>) => void;
  onRequestRefreshSessions?: () => void;
}
export default function EditTodaysSessionModal(props: Readonly<EditTodaysSessionModalProps>) {
  const { open, startDate, endDate, onClose, editedSession, saveSession, setDraftSession } = props;

  const userStateInUS = useXNGSelector(selectStateInUS);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<SchedulerFieldValues>({
    resolver: yupResolver(EDIT_SESSION_METADATA_MODAL_VALIDATION_SCHEMA),
  });

  const onEditSubmit = async (data: SchedulerFieldValues) => {
    // Timezone adjustments
    const { timezoneAdjustedStartTime, timezoneAdjustedEndTime } = timezoneAdjustedStartOrEndTimes(
      userStateInUS,
      "data",
      data.startTime,
      data.endTime,
    );

    const updateSessionRequest: UpdateSessionRequest = {
      id: props.editedSession!.id,
      title: data.title,
      service: {
        id: data.service.id,
        name: data.service.name,
        description: data.service.description,
        area: data.service.area,
        type: data.service.serviceType,
      },
      groupSetting: data.sessionType === "group",
      meetingDetails: {
        date: dayjs(data.startTime).startOf("day").toDate(),
        startTime: timezoneAdjustedStartTime as Date,
        endTime: timezoneAdjustedEndTime as Date,
        location: { name: data.location, description: data.locationDescription } as Location, // Todo: ID later
      },
      studentJournalList: props.editedSession!.studentJournalList?.map((item) => ({
        ...item,
        studentAttendanceRecord: {
          ...item.studentAttendanceRecord,
          arrivalTime: timezoneAdjustedStartTime as Date,
          departureTime: timezoneAdjustedEndTime as Date,
        },
      })),
    };

    const updatedSession = produce(props.editedSession || {}, (draft) => {
      draft = Object.assign(draft, updateSessionRequest);
    });

    if (setDraftSession) {
      setDraftSession(updatedSession);
    }
    saveSession(updatedSession).then(() => {
      reset();
    });

    if (props.onRequestRefreshSessions) {
      props.onRequestRefreshSessions();
    }

    onClose();
  };
  const editSessionFormWrapperProps = {
    open,
    startDate,
    endDate,
    onClose,
    editedSession,
    submitCallback: onEditSubmit,
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
    setValue,
  };
  return <EditSessionFormWrapper modalType="single" {...editSessionFormWrapperProps} />;
}
