import { ReportStatus } from "../context/reducer/progress_reporting_reducer";
import { ProgressDataColumnNames, ProgressSummaryDataGrid_MUI_RowDataProps } from "./progress_summary_data_grid_manager";
import {   getFormattedDateTime } from "../../../../admin/views/district_profile/utils/date_utils";
import { ExtendedStudentProgressDetailsWithAggregatedProgress, ExtendedStudentProgressReportRecordUIPaginatedReportResponse, ExtendedStudentProgressReportRecordUIWithIsPosted, GroupedStudentReport, ObjectiveWithExtendedGoalProgressDetailsWithIsPostedStatus } from "../types/extended_progress_repoting_types";
import { getProgress } from "./progress_helper";
import { isValidArray } from "./validators";
import {utils, WorkSheet, writeFile} from "xlsx"; 


interface GoalAreaWithProgressReport {
    goalArea:string;
    goalProgressDetails:ExtendedStudentProgressDetailsWithAggregatedProgress[];
    goalDescription:string;
}

 

export interface FormattedStudentReport{
    studentName:string
    goalNameWithGoalProgressReport:GoalAreaWithProgressReport[]
    objectiveNameWithObjectiveProgressReport:ObjectiveWithExtendedGoalProgressDetailsWithIsPostedStatus[]
}



export class xlsxReportGeneratorManager {
  static sortByStudent(arg: ExtendedStudentProgressReportRecordUIPaginatedReportResponse): FormattedStudentReport[] {
    const resultMap = new Map<string, FormattedStudentReport>();
    arg?.pageRecords?.forEach((record: ExtendedStudentProgressReportRecordUIWithIsPosted) => {
      
      const studentName = record.studentName || "Unknown Student"; 
      const goalProgressDetails =  {
         goalArea: record?.goal?.goalArea || "Unknown Goal",
         goalProgressDetails: record?.goal?.goalProgressDetails,
         goalDescription:record?.goal?.goalDescription
      }
  
      
      // Check if the student already exists in the resultMap
      if (resultMap.has(studentName)) { 
        const existingStudent = resultMap.get(studentName); 
        existingStudent?.goalNameWithGoalProgressReport.push(goalProgressDetails as GoalAreaWithProgressReport);
        if(record?.objectives&&Array.isArray(record?.objectives)){
          existingStudent?.objectiveNameWithObjectiveProgressReport.push(...record.objectives);
        } 
      } else {
        // Add a new entry for the student 
        resultMap.set(studentName, {
          studentName: studentName,
          goalNameWithGoalProgressReport: [goalProgressDetails as GoalAreaWithProgressReport],
          objectiveNameWithObjectiveProgressReport: record?.objectives
        });
      }
    });
  
    // Convert the map back to an array
    return Array.from(resultMap.values());
  }

  static sortByStudentForGroupedStudentReport(arg: GroupedStudentReport[]): FormattedStudentReport[] {
    return arg?.map((record: GroupedStudentReport):FormattedStudentReport => {
         const studentName = record.studentName || "Unknown Student";
         const result: FormattedStudentReport = {studentName: studentName, goalNameWithGoalProgressReport: [], objectiveNameWithObjectiveProgressReport: []}
          
         record?.allStudentProgressReportUnderCurrentStudent.map((item) => {
             result?.goalNameWithGoalProgressReport.push({
                 goalArea: item?.goal?.goalArea || "Unknown Goal",
                 goalProgressDetails: item?.goal?.goalProgressDetails,
                 goalDescription:item?.goal?.goalDescription as string
             })
             if(item?.objectives&&Array.isArray(item?.objectives)){
               result?.objectiveNameWithObjectiveProgressReport.push(...item.objectives as any);
            }
         })

        return result
    })
    
  }
  

  static generateXlsxReport(data: FormattedStudentReport[],isTeamAggregated:boolean,isLastSessionData:boolean) {
    const workbook = utils.book_new();

    // Iterate over each student's report
    data.forEach(studentReport => {
      const sheetData: any[] = [];

      // Add headers for Goal Progress
      sheetData.push(['Student Name: ' + studentReport.studentName]);
    


      // Add Goal Progress Rows
      studentReport.goalNameWithGoalProgressReport.forEach((goal) => {
        sheetData.push(['Goal Area : '+ (goal.goalArea ?? '-')]); // Goal Area
        sheetData.push(['Goal Description : '+ (goal.goalDescription ?? '-')]); // Goal Area
        
        sheetData.push([ 
          'Period Start',
          'Period End',
          'Progress Measured',
          'Comments',
          'Reported By', 
        ]);
        goal.goalProgressDetails?.forEach(goalProgress => {
          if(goalProgress?.status?.toLocaleLowerCase() !== ReportStatus.Posted) return
          sheetData.push([       // Goal Area
            getFormattedDateTime(goalProgress.periodStartDate?.toDateString() as string).date || '-', // Period Start
            getFormattedDateTime(goalProgress.periodEndDate?.toDateString() as string).date || '-',   // Period End
            `${getProgress(isTeamAggregated,isLastSessionData,goalProgress)}%`, // Progress Measured
            goalProgress.generalComments ?? '-',  // Comments
            goalProgress.progressReportedBy ?? '-' 
          ]);
        });
        sheetData.push([]);
      });

      // Add a separator row between Goal and Objective sections
      if(studentReport.goalNameWithGoalProgressReport?.length === 0)  {
        sheetData.push([]);
      } 

      // Add Objective Progress Rows separately
      studentReport.objectiveNameWithObjectiveProgressReport.forEach(objective => {
        const postedObjectives = objective.objectiveProgressDetails?.filter((objectiveProgress) => objectiveProgress.status?.toLocaleLowerCase() === ReportStatus.Posted)
        if(!isValidArray(objective.objectiveProgressDetails)||!isValidArray(postedObjectives) ) return;
        sheetData.push(['Objective Area : '+ (objective.objectiveArea ?? '-')]); // Objective Area
        sheetData.push(['Objective Description : '+ (objective.objectiveDescription ?? '-')]); // Objective Area
        sheetData.push([ 
          'Period Start',
          'Period End',
          'Progress Measured',
          'Comments',
          'Reported By', 
        ]);
        objective.objectiveProgressDetails?.forEach(objectiveProgress => {
          if(objectiveProgress?.status?.toLocaleLowerCase() !== ReportStatus.Posted) return
          sheetData.push([ 
            getFormattedDateTime(objectiveProgress.periodStartDate?.toDateString() as string).date || '-', // Period Start
            getFormattedDateTime(objectiveProgress.periodEndDate?.toDateString() as string).date || '-',   // Period End
            `${getProgress(isTeamAggregated,isLastSessionData,objectiveProgress as ExtendedStudentProgressDetailsWithAggregatedProgress)}%`, // Progress Measured
            objectiveProgress.generalComments || '-',  // Comments
            objectiveProgress.progressReportedBy ?? '-' 
          ]);
        });
        sheetData.push([]);
      });

      // Convert sheetData to worksheet
      const worksheet = utils.aoa_to_sheet(sheetData);

      // Style and add borders to the worksheet
      this.applySheetStyles(worksheet, sheetData.length);

      // Add the worksheet to the workbook
      utils.book_append_sheet(workbook, worksheet, studentReport.studentName || 'Unknown Student');
    });

    // Return workbook for further use or export
    return workbook;
  }

  static generateXlsxReportForProgressSummary(data: ProgressSummaryDataGrid_MUI_RowDataProps[]) {
    const headers = Object.values(ProgressDataColumnNames) 

    // Exclude 'id' from the data rows
    const filteredData = data.map(({ id, ...rest }) => rest); // Remove 'id' field

    // Create a new worksheet with headers and rows
    const worksheet = utils.json_to_sheet(filteredData, { header: headers });
     
    this.applySheetStylesForProgressSummary(worksheet, filteredData.length+1);

    // Create a workbook
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Progress Summary");
  
    // Trigger the file download
    writeFile(workbook, "ProgressSummary.xlsx");

    return workbook
  
  }


  static applySheetStylesForProgressSummary(worksheet: WorkSheet, rowCount: number) {
    // Set column widths for better readability
    worksheet['!cols'] = [
      { wpx: 150 }, // Student Name
      { wpx: 150 }, // District
      { wpx: 150 }, // Campus
      { wpx: 100 }, // Student ID
      { wpx: 120 }, // Student DOB
      { wpx: 200 }, // Number of Goals on File
      { wpx: 200 }, // Number of Goals Created
      { wpx: 200 }, // Number of Goals Submitted
      { wpx: 200 }, // Number of Goals Posted
      { wpx: 200 }, // Staff Reported Progress
    ];

    // Apply border and center alignment to each cell
    for (let R = 0; R < rowCount; R++) {
      for (let C = 0; C < 10; C++) { // Assuming 10 columns after removing 'ID'
        const cellRef = utils.encode_cell({ r: R, c: C });
        if (!worksheet[cellRef]) continue;
        worksheet[cellRef].s = {
          border: {
            top: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
            left: { style: 'thin', color: { rgb: '000000' } },
          },
          alignment: { vertical: 'center', horizontal: 'center' }, // Center the text
        };
      }
    }

    // Style the headers with bold font and a background fill color
    ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1'].forEach(cell => {
      if (worksheet[cell]) {
        worksheet[cell].s = {
          font: { bold: true },
          fill: { fgColor: { rgb: 'CCCCFF' } }, // Light blue header
          border: {
            top: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
            left: { style: 'thin', color: { rgb: '000000' } },
          },
          alignment: { vertical: 'center', horizontal: 'center' }, // Center the headers
        };
      }
    });
  }



  // Helper function to apply styles and borders to the sheet
  static applySheetStyles(worksheet: WorkSheet, rowCount: number) {
    // Set column widths for better readability
    worksheet['!cols'] = [{ wpx: 200 }, { wpx: 200 }, { wpx: 100 }, { wpx: 300 }, { wpx: 150 }, { wpx: 100 }];

    // Apply border to each cell
    for (let R = 0; R < rowCount; R++) {
      for (let C = 0; C < 6; C++) {
        const cellRef = utils.encode_cell({ r: R, c: C });
        if (!worksheet[cellRef]) continue;
        worksheet[cellRef].s = {
          border: {
            top: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
            left: { style: 'thin', color: { rgb: '000000' } },
          },
          alignment: { vertical: 'center', horizontal: 'left' },
        };
      }
    }

    // Style the headers with bold font
    ['A1', 'B1', 'C1', 'D1', 'E1', 'F1'].forEach(cell => {
      if (worksheet[cell]) {
        worksheet[cell].s = {
          font: { bold: true },
          fill: { fgColor: { rgb: 'CCCCFF' } },
          border: {
            top: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
            left: { style: 'thin', color: { rgb: '000000' } },
          },
          alignment: { vertical: 'center', horizontal: 'center' },
        };
      }
    });
  }


}
