import * as yup from "yup";

const ERROR = "Entry is required";

export const EDIT_SESSION_METADATA_MODAL_VALIDATION_SCHEMA = yup.object().shape({
  title: yup.string().required(ERROR),
  sessionType: yup.string().required(ERROR),
  dateOccurs: yup.date().required(ERROR),
  startTime: yup.date().required(ERROR),
  endTime: yup.date().required(ERROR),
  minutesDuration: yup
    .number()
    .typeError("Entry is not a number")
    .required(ERROR)
    .min(1, "Duration must be over 0"),
  location: yup.string().required(ERROR),
});
