import { Button, SxProps, Theme } from '@mui/material'
import React from 'react'

export const buttonSxStyles:SxProps<Theme> ={
    minWidth: "178px",
    px: "2rem",
    py: "1.5rem",
}

const ProgressReportingStandardBtn:React.FC<{onSubmit?: () => void,title?:string,sxOverRide: SxProps}> = ({
    onSubmit,
    title,
    sxOverRide 
}) => {
  return (
    <Button
    onClick={onSubmit}
    sx={{...buttonSxStyles,...sxOverRide}}
>
    {title}
   </Button>
  )
}

export default ProgressReportingStandardBtn