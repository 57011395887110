import { StudentProgressReportRecordUI, StudentProgressReportUpdateParameters } from "@xng/reporting";
import { ServiceProviderResponse } from "../../../../../profile-sdk";

export class ProgressReportApiParametersGenerator{
    public static generateParamsForProgressReportUpdateForAggregatedData(goalId:string, period:number, currentlyLoggedInServiceProvider:ServiceProviderResponse,pageRecords:StudentProgressReportRecordUI[],currentlySelectedServiceProviderId:string,objectiveId?:string ):StudentProgressReportUpdateParameters[]{
        const result : StudentProgressReportUpdateParameters[] = []

        pageRecords.forEach((report)=>{
            if(report?.goal?.goalId === goalId && report.serviceProviderId === currentlySelectedServiceProviderId){
                  report?.goal?.goalProgressDetails?.forEach((goalProgressDetail)=>{
                       if(period === goalProgressDetail.period){
                           result.push({
                               id:report.id,
                               reportRunId:report.reportRunId,
                               objectiveId:objectiveId ?? "", 
                               period:goalProgressDetail.period,
                               reportFinalizedBy:currentlyLoggedInServiceProvider.firstName + " " + currentlyLoggedInServiceProvider.lastName,
                           })
                       }
                  })
            }
        })
 
        return result
    }

    public static generateParamsForProgressReportUpdateForTeamAggregatedData(goalId:string, period:number, currentlyLoggedInServiceProvider:ServiceProviderResponse , pageRecords:StudentProgressReportRecordUI[], objectiveId?:string ) : StudentProgressReportUpdateParameters[]{
       const result : StudentProgressReportUpdateParameters[] = []

       pageRecords.forEach((report)=>{
           if(report?.goal?.goalId === goalId){
                 report?.goal.goalProgressDetails?.forEach((goalProgressDetail)=>{
                      if(period === goalProgressDetail.period ){
                          result.push({
                              id:report.id,
                              reportRunId:report.reportRunId,
                              objectiveId:objectiveId??"",
                              period:goalProgressDetail.period,
                              reportFinalizedBy:currentlyLoggedInServiceProvider.firstName + " " + currentlyLoggedInServiceProvider.lastName,
                          })
                      }
                 })
           }
       })

       return result
    }
}